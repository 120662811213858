import { ExportOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import StatusLabel from 'common/components/StatusLabel';
import useDidMount from 'common/hooks/useDidMount';
import { exportOrdersService, getAllOrdersService } from 'common/services/extends/orders';
import { PaymentStatus } from 'common/services/extends/orders/types';
import {
  ROUTE_PATHS
} from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles from 'configs/roles';

export interface OrdersData {
  id: number,
  studentName: string,
  studentEmail: string,
  studentPhone: string,
  paymentMethod: string,
  status: PaymentStatus,
  createdAt: string,
  updatedAt: string,
}

const OrdersManagement: React.FC<ActiveRoles> = ({ roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize, advancedFilter } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('order', advancedFilter),
    [advancedFilter]
  );

  /* Queries */
  const { data: ordersData, isLoading } = useQuery(
    ['getAllOrdersService', keyword, currentPage, currentView, selectedFilterList],
    () => getAllOrdersService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportOrdersMutate'],
    exportOrdersService,
    {
      onSuccess(res) {
        if (res) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('Something wrong! Please try again'));
      }
    }
  );

  const tableData: OrdersData[] = useMemo(() => (
    ordersData?.data.map((val) => ({
      id: val.orderData?.id,
      studentName: val.orderData?.student?.name || '',
      studentEmail: val.orderData?.student?.email || '',
      studentPhone: val.orderData?.student?.phone || '',
      paymentMethod: val.orderData?.paymentMethod || '',
      status: val.orderData.status || '',
      createdAt: val.orderData?.createdAt || '',
      updatedAt: val.orderData?.updatedAt || '',
  })) || []), [ordersData]);

  const columns: ColumnsType<OrdersData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      key: 'studentName',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {data.studentName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      key: 'studentEmail',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {data.studentEmail || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      key: 'studentPhone',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {data.studentPhone || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('order.paymentMethod'),
      key: 'paymentMethod',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {data.paymentMethod || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 160,
      render: (_name: string, data: OrdersData) => (
        <StatusLabel status={data.status} />
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: OrdersData) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: OrdersData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigation(`${ROUTE_PATHS.ORDERS_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.orders')}
        rightHeader={
          (
            <Space>
              <Button
                type="primary"
                disabled={!roleOther.includes(roles.ORDERS_EXPORT)}
                loading={exportLoading}
                onClick={() => exportMutate({ keyword })}
              >
                <ExportOutlined />
                {t('system.export')}
              </Button>
            </Space>
          )
        }
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: ['id', 'studentName', 'studentEmail', 'studentPhone', 'paymentMethod', 'status', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: ordersData?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default OrdersManagement;
