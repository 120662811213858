import {
  CreateOnboardingParams,
  DeleteOnboardingTranslationParams,
  OnboardingTypes,
  UpdateOnboardingParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllOnboardingsService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<OnboardingTypes[]>> => {
  const res = await axiosInstance.get('on-boarding', { params });
  return res.data;
};

export const getOnboardingByIdService = async (id: number): Promise<OnboardingTypes> => {
  const res = await axiosInstance.get(`on-boarding/${id}`);
  return res.data.data;
};

export const createOnboardingService = async (
  params: CreateOnboardingParams,
): Promise<{ id: number }> => {
  const res = await axiosInstance.post('on-boarding', params);
  return res.data.data;
};

export const updateOnboardingService = async (params: UpdateOnboardingParams): Promise<void> => {
  await axiosInstance.put(`on-boarding/${params.id}`, params);
};

export const deleteOnboardingService = async (
  ids: number[],
): Promise<void> => {
  await axiosInstance.delete('on-boarding', { data: { ids } });
};

export const deleteOnboardingTranslationService = async (
  params: DeleteOnboardingTranslationParams,
): Promise<void> => {
  await axiosInstance.delete('on-boarding/translations', { data: params });
};
