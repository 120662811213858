import {
  FunctionalDetailTypes,
  FunctionalsDataTypes,
  UpdateFunctionalParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllFunctionalsService = async (
  params?: BaseFilterParams,
): Promise<APIPaginationResponse<FunctionalsDataTypes[]>> => {
  const res = await axiosInstance.get('functionals', { params });
  return res.data;
};

export const getFunctionalByIdservice = async (
  id: string,
): Promise<FunctionalDetailTypes> => {
  const res = await axiosInstance.get(`functionals/${id}`);
  return res.data.data;
};

export const updateFunctionalByIdservice = async (
  params: UpdateFunctionalParams,
): Promise<void> => {
  await axiosInstance.put(`functionals/${params.id}`, params);
};
