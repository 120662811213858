import {
  CreateUserLevelParams, UpdateUserLevelParams, UserLevelData, UserLevelDetail
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllUserLevelService = async (
  params?: BaseFilterParams,
): Promise<APIPaginationResponse<UserLevelData[]>> => {
  const res = await axiosInstance.get('user-levels', { params });
  return res.data;
};

export const getUserLevelService = async (
  id: string,
): Promise<UserLevelDetail> => {
  const res = await axiosInstance.get(`user-levels/${id}`);
  return res.data.data;
};

export const createUserLevelService = async (
  params: CreateUserLevelParams,
): Promise<void> => {
  await axiosInstance.post('user-levels', params);
};

export const updateUserLevelService = async (
  params: UpdateUserLevelParams,
): Promise<void> => {
  await axiosInstance.put(`user-levels/${params.id}`, params);
};
