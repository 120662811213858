import {
  Card, Col, Row, Space, Spin, Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {
  useQuery
} from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import { getSolutionSearchByIdService } from 'common/services/solutionSearch';
import { Grade } from 'common/services/solutionSearch/types';

const EditSolutionSearch: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  /* States */
  const idParams = searchParams.get('id');

  /* Queries */
  const { data: solutionSearchDetail, isLoading } = useQuery(
    ['geSolutionSearchById', idParams],
    () => {
      if (idParams) {
        return getSolutionSearchByIdService(idParams);
      }
      return undefined;
    },
    {
      enabled: roleIndex && !!idParams,
      refetchOnMount: 'always',
    }
  );

  const formatData = useMemo(() => (solutionSearchDetail ? ({
    question: solutionSearchDetail.solutionSearchData.question || '',
    result: solutionSearchDetail.solutionSearchData.result,
    response: solutionSearchDetail.solutionSearchData.response,
    images: solutionSearchDetail.solutionSearchData.images,
    error: solutionSearchDetail.solutionSearchData.error || '',
    grade: solutionSearchDetail.solutionSearchData.grade ? Grade[solutionSearchDetail.solutionSearchData.grade] : '',
    token: solutionSearchDetail.solutionSearchData.token?.toString(),
    studentName: solutionSearchDetail.student?.name || '',
  }) : {}), [solutionSearchDetail]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.solutionSearchDetail')}
      />
      <Spin spinning={isLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <Card
                  type="inner"
                  title={t('solutionSearch.studentInfo')}
                >
                  <div className="site-card-border-less-wrapper">
                    <Space direction="vertical" size={24} style={{ width: '100%' }}>
                      {/* Mô tả  */}
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('solutionSearch.studentName')}
                              {' '}
                            </Typography.Text>
                            <div className="p-editSolutionSearch_customInput u-mt-8">
                              <Typography.Text>
                                {formatData.studentName || ''}
                              </Typography.Text>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </div>
                </Card>
                <Card type="inner">
                  {/* Tiêu đề  */}
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="p-editPageTemplate_input">
                        <Typography.Text strong>
                          {t('solutionSearch.question')}
                          {' '}
                        </Typography.Text>
                        <div className="p-editSolutionSearch_customInput u-mt-8">
                          <Typography.Text>
                            {formatData.question || ''}
                          </Typography.Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="p-editPageTemplate_input u-mt-8">
                        <Typography.Text strong>
                          {t('solutionSearch.result')}
                          {' '}
                        </Typography.Text>
                        <div className="p-editSolutionSearch_customInput u-mt-8">
                          <ReactMarkdown>
                            {formatData.result || ''}
                          </ReactMarkdown>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mt-8">
                        <Typography.Text strong>
                          {t('solutionSearch.grade')}
                          {' '}
                        </Typography.Text>
                        <div className="p-editSolutionSearch_customInput u-mt-8">
                          <Typography.Text>
                            {formatData.grade || ''}
                          </Typography.Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mt-8">
                        <Typography.Text strong>
                          {t('solutionSearch.token')}
                          {' '}
                        </Typography.Text>
                        <div className="p-editSolutionSearch_customInput u-mt-8">
                          <Typography.Text>
                            {formatData.token || ''}
                          </Typography.Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="p-editPageTemplate_input u-mt-8">
                        <Typography.Text strong>
                          {t('solutionSearch.error')}
                          {' '}
                        </Typography.Text>
                        <div className="p-editSolutionSearch_customInput u-mt-8">
                          <Typography.Text>
                            {formatData.error || ''}
                          </Typography.Text>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card
                  type="inner"
                  title={t('solutionSearch.response')}
                >
                  <div className="site-card-border-less-wrapper">
                    <Space direction="vertical" size={24} style={{ width: '100%' }}>
                      {/* Mô tả  */}
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input">
                            <Typography.Text strong>
                              {t('solutionSearch.token')}
                              {' '}
                            </Typography.Text>
                            <div className="p-editSolutionSearch_customInput u-mt-8">
                              <Typography.Text>
                                {formatData.token || ''}
                              </Typography.Text>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('solutionSearch.question')}
                              {' '}
                            </Typography.Text>
                            <div className="p-editSolutionSearch_customInput u-mt-8">
                              <ReactMarkdown>
                                {formatData.response?.imageOcr?.question
                                  || formatData.response?.question || ''}
                              </ReactMarkdown>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('solutionSearch.answer')}
                              {' '}
                            </Typography.Text>
                            <div className="p-editSolutionSearch_customInput u-mt-8">
                              <ReactMarkdown>
                                {formatData.response?.imageOcr?.answer || ''}
                              </ReactMarkdown>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="p-editPageTemplate_input u-mt-8">
                            <Typography.Text strong>
                              {t('solutionSearch.result')}
                              {' '}
                            </Typography.Text>
                            <div className="p-editSolutionSearch_customInput u-mt-8">
                              <ReactMarkdown>
                                {formatData.response?.result || ''}
                              </ReactMarkdown>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Space>
                  </div>
                </Card>
              </Space>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={solutionSearchDetail ? dayjs(solutionSearchDetail.solutionSearchData.createdAt).fromNow() : ''}
                createdBy=""
                lastUpdated={solutionSearchDetail ? dayjs(solutionSearchDetail.solutionSearchData.updatedAt).fromNow() : ''}
                lastUpdatedBy=""
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default EditSolutionSearch;
