import {
  Card, Col, Row,
  Spin, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import { getStudentByIdService } from 'common/services/extends/students';
import { formatDateTime } from 'common/utils/functions';

const StudentDetail: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const codeParams = searchParams.get('id');

  /* Queries */
  const { data: studentDataDetail, isLoading } = useQuery(
    ['getStudentByIdService', codeParams],
    () => {
      if (codeParams) {
        return getStudentByIdService({ id: codeParams });
      }
      return null;
    },
    {
      enabled: !!codeParams && roleIndex,
    }
  );

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.students')}
      />
      <Spin size="large" spinning={isLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={24}>
              <Card type="inner">
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.name')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {studentDataDetail?.studentsData?.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.email')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {studentDataDetail?.studentsData?.email || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('student.source')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {studentDataDetail?.studentsData?.source || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('student.sourceId')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {studentDataDetail?.studentsData?.sourceId || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.createdAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(studentDataDetail?.studentsData?.createdAt) || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.updatedAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(studentDataDetail?.studentsData?.updatedAt) || ''}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default StudentDetail;
