import axiosInstance from '../common/instance';

import { TUsageHistoryData, TUsageHistoryDetailData } from './types';

export const getUsageHistoriesService = async (params: BaseFilterParams)
  : Promise<APIPaginationResponse<TUsageHistoryData[]>> => {
  const res = await axiosInstance.get('usage-histories', { params });
  return res.data;
};

export const getUsageHistoryByIdService = async (id: number)
  : Promise<TUsageHistoryDetailData> => {
  const res = await axiosInstance.get(`usage-histories/${id}`);
  return res.data.data;
};
