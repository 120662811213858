import {
  Card, Col, Modal, ModalProps, Row, Space, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TFunctionalData } from 'common/services/usageHistories/types';

const FunctionalDetailModal: React.FC<ModalProps & { data: TFunctionalData[] }> = ({
  open, data, onOk, onCancel
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('usageHistories.functionalDetail')}
      width="60vw"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {
        data.map((f) => (
          <Card title={f.functionalType} key={`${f.functionalType}`} className="u-mt-16">
            <Row gutter={16}>
              <Col span={12}>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.webOrWeekLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {f.webOrWeekLimit}
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.remainWebOrWeekLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {f.remainWebOrWeekLimit}
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.appOrMonthLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {f.appOrMonthLimit}
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.remainAppOrMonthLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {f.remainAppOrMonthLimit}
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.totalUsed')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {f.totalUsed}
                  </Typography.Text>
                </Space>
              </Col>
            </Row>
          </Card>
        ))
      }
    </Modal>

  );
};

export default FunctionalDetailModal;
