import { FeatureHistoriesTypes, SelectedUserTypes } from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllFeatureHistoriesService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<FeatureHistoriesTypes[]>> => {
  const res = await axiosInstance.get('feature-histories', { params });
  return res.data;
};

export const getSelectedUsersService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<SelectedUserTypes[]>> => {
  const res = await axiosInstance.get('feature-histories/select-user-used', { params });
  return res.data;
};
