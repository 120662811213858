import {
  CheckOutlined,
  DeleteOutlined, EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Modal,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, {
  useEffect,
  useMemo, useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FiltersDataTable, { FilterDataProps, FilterValueProps, RenderTagsListFilter } from './filter';
import FilterTableColumn from './filterTableCol';
import SearchInput from './searchInput';

import { useAppSelector } from 'app/store';
import { locationSchema } from 'common/utils/schemas';

type FilterDataTypes = {
  province?: number;
  district?: number;
  wards?: number;
};

export type MenuItemTableTypes = {
  code: string;
  id: number;
  key: string;
  locale: string[];
  title: string;
};
interface PageTableProps<T> {
  isLoading?: boolean;
  noCheckbox?: boolean;
  roles?: ActionRoles;
  tableProps?: {
    initShowColumns?: string[];
    columns?: ColumnsType<T>;
    filterFields?: FilterDataProps[];
    pageData?: T[];
    total?: number;
    pageSize?: number;
    currentPage?: number;
    noBaseCol?: boolean;
    noDeleteLanguage?: boolean;
    isHidePagination?: boolean;
    handleSetCurrentPage?: (page: number) => void;
    handleSetCurrentView?: (view: number) => void;
    addonPageSize?: number[];
  }
  hasFilterLocation?: boolean;
  filtersDataTable?: {
    extendedFilter?: React.ReactNode;
    handleFilter?: (data: FilterValueProps) => void;
    selectedFilterList?: FilterValueProps[];
    handleDeleteFilter?: (key: string) => void;
    handleSelectProvince?: (province?: number) => void;
    handleSelectDistrict?: (district?: number) => void;
    handleSelectWards?: (wards?: number) => void;
    provinceValue?: number;
    districtValue?: number;
    wardsValue?: number;
  }
  handleDelete?: (data: T[], lang?: string) => void;
  handleEditPage?: (id: number, code: string, language: string) => void;
  handleCreatePage?: (id: number, code: string, language: string) => void;
  handleSearch?: (keyword: string) => void;
  statusDataTable?: {
    handleChangeStatus?: (data: T[], status: number) => void;
    changeStatusLoading?: boolean;
    canChangeStatusApprove?: boolean;
    statusOptions?: OptionType[];
  }
  buckActions?: OptionType[];
  handleChangeBulkAction?: (action: string, selected: T[]) => void;
  isActiveStatus?: boolean;
  handleSelectRows?: (selectedRowKeys: React.Key[], selectedRows: T[]) => void;
  leftCustomForm?: React.ReactNode,
  disableIds?: number[];
}
const PageTable = <T extends any>({
  isLoading,
  handleDelete,
  handleEditPage,
  handleCreatePage,
  handleSearch,
  tableProps,
  noCheckbox,
  roles,
  filtersDataTable,
  statusDataTable,
  buckActions,
  handleChangeBulkAction,
  isActiveStatus,
  handleSelectRows,
  leftCustomForm,
  disableIds,
  hasFilterLocation,
}: PageTableProps<T>) => {
  /* Hooks */
  const { t } = useTranslation();
  const { initialData, defaultPageSize } = useAppSelector((state) => state.system);
  const cellAlgnmentObj = { width: '100%', justifyContent: 'center' };
  /* States */
  const [selectedRowsState, setSelectedRowsState] = useState<T[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<string[]>(tableProps?.initShowColumns || []);
  const [action, setAction] = useState<string | undefined>(undefined);
  const [statusChange, setStatusChange] = useState<number | undefined>(undefined);
  /* Variables */
  const bulkActions: OptionType[] = [
    ...(handleDelete ? [{
      label: t('system.delete'),
      value: 'delete',
    }] : []),
    ...(statusDataTable?.handleChangeStatus ? [{
      label: t('system.changeStatus'),
      value: 'status',
    }] : []),
    ...(buckActions || []),
  ];
  const statusActions = useMemo(() => {
    if (statusDataTable?.statusOptions) {
      return statusDataTable?.statusOptions;
    }
    return [
      {
        label: t('system.saveDraft'),
        value: 1,
      },
      {
        label: t('system.sendApprove'),
        value: 7,
      },
      ...(statusDataTable?.canChangeStatusApprove ? [{
        label: t('system.saveApproved'),
        value: 13,
      }] : []),
    ];
  }, [statusDataTable, t]);

  const statusActionsProvinces = useMemo(() => {
    if (statusDataTable?.statusOptions) {
      return statusDataTable?.statusOptions;
    }
    return [
      {
        label: t('system.active'),
        value: 1,
      },
      {
        label: t('system.inactive'),
        value: 7,
      },
    ];
  }, [statusDataTable, t]);
  /* Functions */
  const handleSelectedColumn = (val: string) => {
    if (!selectedColumn.includes(val)) {
      setSelectedColumn([...selectedColumn, val]);
    } else if (selectedColumn.length > 1) {
      setSelectedColumn(selectedColumn.filter((item) => item !== val));
    }
    if (!selectedColumn.length) {
      setSelectedColumn([val]);
    }
  };
  const dataSource = useMemo(() => (tableProps?.pageData ? tableProps.pageData.map((val: any) => ({
    ...val,
    key: val.id?.toString(),
  })) : []), [tableProps?.pageData]);
  const paginationSize = useMemo(() => {
    if (!initialData) {
      return [];
    }
    return [...initialData.paginationOptions.numbersOfRows.map((option) => option.numbers),
    ...tableProps?.addonPageSize || []];
  }, [initialData, tableProps?.addonPageSize]);
  const renderButton = (data: any, allowLocale: string, localeItem?: string) => {
    const enableLocale = localeItem ? localeItem.includes(allowLocale) : false;
    return (
      <div>
        {enableLocale ? (
          <Space size={10}>
            <Button
              disabled={!roles?.roleUpdate}
              icon={<EditOutlined />}
              onClick={
                () => handleEditPage && handleEditPage(data.id, data.code, allowLocale)
              }
            />
            {!tableProps?.noDeleteLanguage && (
              <Button
                disabled={!roles?.roleDelete}
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    className: 't-pagetable_deleteLanguageModal',
                    autoFocusButton: 'cancel',
                    okText: t('system.ok'),
                    cancelText: t('system.cancel'),
                    cancelButtonProps: {
                      type: 'primary',
                    },
                    okButtonProps: {
                      type: 'default',
                    },
                    title: t('message.confirmDeleteLanguage'),
                    onOk: () => {
                      if (handleDelete) handleDelete([data], allowLocale);
                    },
                  });
                }}
              />
            )}
          </Space>
        ) : (
          <Button
            disabled={!roles?.roleCreate}
            icon={<PlusOutlined />}
            onClick={
              () => handleCreatePage && handleCreatePage(data.id, data.code, allowLocale)
            }
          />
        )}
      </div>
    );
  };
  const renderColumnLocale: ColumnsType<T> = useMemo(() => {
    if (!initialData) {
      return [];
    }
    return Object.keys(initialData.websiteLocales).map((locale) => ({
      title: locale.toUpperCase(),
      key: locale,
      width: 90,
      align: 'center',
      render: (_name: string, data: any) => (
        <Space direction="horizontal" style={cellAlgnmentObj}>
          {renderButton(data, locale, typeof data.locale === 'string' ? data.locale : Object.keys(data.locale).toString())}
        </Space>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);
  const baseColumns: ColumnsType<any> = [
    ...renderColumnLocale,
    {
      title: t('system.action'),
      key: 'action',
      width: 90,
      align: 'center',
      render: (_name: string, data: any) => (
        <Button
          disabled={!roles?.roleDelete}
          icon={<DeleteOutlined />}
          onClick={() => {
            Modal.confirm({
              className: 't-pagetable_deleteRecordModal',
              autoFocusButton: 'cancel',
              title: t('message.confirmDeleteRecord'),
              okText: t('system.ok'),
              cancelText: t('system.cancel'),
              cancelButtonProps: {
                type: 'primary',
              },
              okButtonProps: {
                type: 'default',
              },
              onOk: () => {
                if (handleDelete) handleDelete([data], 'all');
              },
            });
          }}
        />
      ),
    },
  ];
  const columns: ColumnsType<any> = tableProps?.noBaseCol
    ? (tableProps?.columns || []) : [...(tableProps?.columns || []), ...baseColumns];
  const columnSelected = columns.filter((item) => selectedColumn.includes(item.key as string));
  /**
   * RowSelection object indicates the need for row selection
   */
  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: T[]) => {
      setSelectedRowsState(selectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
      if (handleSelectRows) handleSelectRows(newSelectedRowKeys, selectedRows);
    },
    fixed: 'left',
    getCheckboxProps: (record: any) => ({
      disabled: disableIds?.includes(record?.id) || false,
    }),
  };
  /**
   * Delete External button
   */
  const onDeleteAll = () => {
    Modal.confirm({
      title: selectedRowsState.length === dataSource.length
        ? t('message.confirmDeleteAll') : t('message.confirmDeleteRecord'),
      onOk: () => {
        if (handleDelete) {
          handleDelete(selectedRowsState, 'allRow');
          setSelectedRowsState([]);
          setSelectedRowKeys([]);
        }
      },
    });
  };
  const changeStatus = (status: number) => {
    Modal.confirm({
      title: t('message.confirmChangeAllStatus'),
      onOk: () => {
        if (statusDataTable?.handleChangeStatus) {
          statusDataTable.handleChangeStatus(selectedRowsState, status);
          setSelectedRowsState([]);
          setSelectedRowKeys([]);
        }
      },
    });
  };

  const isDisabled = useMemo(
    () => {
      const isExecuted = buckActions?.find((item) => item.value === action)?.isExecuted;
      return !(isExecuted
        || (!!selectedRowKeys.length && isExecuted === undefined));
    },
    [buckActions, selectedRowKeys, action]
  );

  const mainMethod = useForm<FilterDataTypes>({
    resolver: yupResolver(locationSchema),
    mode: 'onChange',
    defaultValues: {
      province: undefined,
      district: undefined,
      wards: undefined,
    }
  });

  // const watchProvinceCode = String(mainMethod.watch('province') || '');
  // const watchDistrictCode = String(mainMethod.watch('district') || '');

  // const { data: provinceRes } = useQuery(
  //   ['getProvinceList'],
  //   () => getProvincesListService(),
  //   {
  //     enabled: hasFilterLocation
  //   }
  // );

  // const { data: districtRes } = useQuery(
  //   ['getDistrictList', watchProvinceCode],
  //   () => getDistrictListService(watchProvinceCode),
  //   {
  //     enabled: !!watchProvinceCode && hasFilterLocation
  //   }
  // );

  // const { data: wardsRes } = useQuery(
  //   ['getWardsList', watchDistrictCode],
  //   () => getWardsListService(watchDistrictCode),
  //   {
  //     enabled: !!watchDistrictCode && hasFilterLocation && !!filtersDataTable?.handleSelectWards
  //   }
  // );

  useEffect(() => {
    if (filtersDataTable?.provinceValue && hasFilterLocation) {
      mainMethod.setValue('province', filtersDataTable.provinceValue);
    }
    if (filtersDataTable?.districtValue && hasFilterLocation) {
      mainMethod.setValue('district', filtersDataTable.districtValue);
    }
    if (filtersDataTable?.wardsValue && hasFilterLocation && filtersDataTable?.handleSelectWards) {
      mainMethod.setValue('wards', filtersDataTable.wardsValue);
    }
  }, [filtersDataTable?.provinceValue, filtersDataTable?.districtValue,
  filtersDataTable?.wardsValue, mainMethod, hasFilterLocation,
  filtersDataTable?.handleSelectWards
  ]);

  return (
    <div className="t-pagetable u-mt-20">
      <div className="t-pagetable_head">
        <Space style={{ flexGrow: 1, alignItems: 'center' }}>
          {handleSearch && (
            <SearchInput
              handleSearch={handleSearch}
            />
          )}
          {filtersDataTable?.extendedFilter}
          {tableProps?.filterFields && tableProps?.filterFields.length > 0 && (
            <FiltersDataTable
              filterList={tableProps?.filterFields || []}
              handleFilter={filtersDataTable?.handleFilter}
            />
          )}
          {!noCheckbox && (
            <Select
              size="middle"
              style={{ width: '140px' }}
              placeholder={t('system.actions')}
              onChange={(val) => setAction(val)}
              value={action}
            >
              {
                bulkActions.map((val, idx) => (
                  <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                    {val.label}
                  </Select.Option>
                ))
              }
            </Select>
          )}
          {/* {
            hasFilterLocation && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormProvider {...mainMethod}>
                  <div className="p-editPageTemplate_input u-mr-8">
                    <Controller
                      name="province"
                      render={({
                        field: { value, onChange },
                      }) => (
                        <DropdownElement
                          locale="vi"
                          options={provinceRes?.map((val) => ({
                            label: val.name,
                            value: val.id,
                          }))}
                          isShowSearch
                          labelInValue
                          noMargin
                          placeholder={t('filterField.provinceName')}
                          value={value}
                          onChange={(val) => {
                            if (filtersDataTable?.handleSelectProvince) {
                              onChange(val?.value);
                              filtersDataTable.handleSelectProvince(val?.value);
                            }
                            if (filtersDataTable?.handleSelectDistrict) {
                              mainMethod.setValue('district', undefined);
                              mainMethod.setValue('wards', undefined);
                              filtersDataTable.handleSelectDistrict(undefined);
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  {
                    filtersDataTable?.handleSelectDistrict && (
                      <div className="p-editPageTemplate_input u-mr-8">
                        <Controller
                          name="district"
                          render={({
                            field: { value, onChange },
                          }) => (
                            <DropdownElement
                              locale="vi"
                              options={districtRes?.map((val) => ({
                                label: val.name,
                                value: val.id,
                              }))}
                              isShowSearch
                              labelInValue
                              noMargin
                              placeholder={t('filterField.districtName')}
                              value={value}
                              onChange={(val) => {
                                if (filtersDataTable?.handleSelectDistrict) {
                                  onChange(val?.value);
                                  filtersDataTable.handleSelectDistrict(val?.value);
                                }
                                if (filtersDataTable?.handleSelectWards) {
                                  mainMethod.setValue('wards', undefined);
                                  filtersDataTable.handleSelectWards(undefined);
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    )
                  }
                  {
                    filtersDataTable?.handleSelectWards && (
                      <div className="p-editPageTemplate_input u-mr-8">
                        <Controller
                          name="wards"
                          render={({ field: { value, onChange } }) => (
                            <DropdownElement
                              locale="vi"
                              options={wardsRes?.map((val) => ({
                                label: val.name,
                                value: val.id,
                              }))}
                              isShowSearch
                              labelInValue
                              noMargin
                              placeholder={t('filterField.wardName')}
                              value={value}
                              onChange={(val) => {
                                if (filtersDataTable?.handleSelectWards) {
                                  onChange(val?.value);
                                  filtersDataTable.handleSelectWards(val?.value);
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    )
                  }
                </FormProvider>
              </div>
            )
          } */}
          {action === 'status' && (
            <Select
              size="middle"
              style={{ width: '130px' }}
              placeholder={`${t('system.select')} ${t('system.status')}`}
              onChange={(val) => setStatusChange(val)}
              value={statusChange}
            >
              {
                !isActiveStatus ? (
                  statusActions.map((val, idx) => (
                    <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                      {val.label}
                    </Select.Option>
                  ))
                ) : (
                  statusActionsProvinces.map((val, idx) => (
                    <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                      {val.label}
                    </Select.Option>
                  ))
                )
              }
            </Select>
          )}
          {action && (
            <Button
              icon={<CheckOutlined />}
              type="primary"
              size="middle"
              loading={statusDataTable?.changeStatusLoading}
              disabled={isDisabled}
              onClick={() => {
                if (action === 'delete') {
                  onDeleteAll();
                } else if (action === 'status' && statusChange) {
                  changeStatus(statusChange);
                } else if (handleChangeBulkAction) {
                  handleChangeBulkAction(action, selectedRowsState);
                }
              }}
            >
              {t('system.apply')}
            </Button>
          )}
          {
            leftCustomForm && (
              leftCustomForm
            )
          }
          {filtersDataTable?.selectedFilterList
            && filtersDataTable?.selectedFilterList.length > 0
            && (
              <RenderTagsListFilter
                handleDeleteFilter={filtersDataTable.handleDeleteFilter}
                selectedFilterList={filtersDataTable.selectedFilterList}
              />
            )}
        </Space>
        <FilterTableColumn
          listFilter={(tableProps?.columns || []).map((item) => (
            { label: item.title as string, key: item.key as string }))}
          listSelected={selectedColumn}
          handleFilter={handleSelectedColumn}
        />
      </div>
      <div className="t-pagetable_wrap u-mt-20">
        <Spin tip={t('system.loading')} spinning={isLoading}>
          <Table
            rowSelection={noCheckbox || !roles?.roleDelete
              ? undefined : rowSelection as TableRowSelection<any>}
            size="small"
            dataSource={dataSource}
            showSorterTooltip={false}
            columns={tableProps?.noBaseCol ? columnSelected : [...columnSelected, ...baseColumns]}
            pagination={{
              pageSize: tableProps?.pageSize,
              pageSizeOptions: paginationSize,
              defaultPageSize,
              showSizeChanger: !!tableProps?.handleSetCurrentView,
              onChange: (page) => {
                if (tableProps?.handleSetCurrentPage) {
                  tableProps.handleSetCurrentPage(page);
                }
              },
              onShowSizeChange: (_, size) => {
                if (tableProps?.handleSetCurrentView) {
                  tableProps.handleSetCurrentView(size);
                }
              },
              total: tableProps?.total,
              current: tableProps?.currentPage,
              hideOnSinglePage: tableProps?.isHidePagination,
            }}
            scroll={{ x: 1367 }}
            tableLayout="auto"
          />
        </Spin>
      </div>
    </div>
  );
};
export default PageTable;
