import { EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { getAllSolutionSearchService } from 'common/services/solutionSearch';
import { Grade } from 'common/services/solutionSearch/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type ColumnTypes = {
  id: number;
  result: string | null;
  question: string | null;
  grade: Grade | null;
  createdAt: string;
  updatedAt: string;
};

const SolutionSearchManagement: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);
  // const rolesUser = useAppSelector((state) => state.auth.roles);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [activeResult, setActiveResult] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { isLoading, data: solutionSearchData } = useQuery(
    ['getAllSolutionSearch', keyword, currentPage, currentView, selectedFilterList],
    () => getAllSolutionSearchService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  /* Variables */
  const columns: ColumnsType<ColumnTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 50,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: ColumnTypes, b: ColumnTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ColumnTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('solutionSearch.question'),
      dataIndex: 'question',
      // align: 'center',
      width: 200,
      key: 'question',
      render: (_: string, data: ColumnTypes) => (
        <Typography.Text>
          {data.question}
        </Typography.Text>
      ),
    },
    // --- Kết quả
    {
      title: t('solutionSearch.result'),
      dataIndex: 'result',
      // align: 'center',
      key: 'result',
      width: 160,
      render: (_: string, data: ColumnTypes) => (
        data.result ? (
          <div className="p-solutionSearch_wrapper">
            <div className="p-solutionSearch_result">
              <Typography.Text>
                {data.result}
              </Typography.Text>
            </div>
            <EyeTwoTone onClick={() => data.result && setActiveResult(data.result)} style={{ fontSize: '20px' }} className="p-solutionSearch_icon" />
          </div>
        ) : (
          <Typography.Text style={{ textAlign: 'center' }} className="p-solutionSearch_result">
            -
          </Typography.Text>
        )
      ),
    },
    // --- Kết quả
    {
      title: t('solutionSearch.grade'),
      dataIndex: 'grade',
      align: 'center',
      key: 'grade',
      width: 100,
      render: (_: string, data: ColumnTypes) => (
        <Typography.Text>
          {data.grade ? Grade[1] : '-'}
        </Typography.Text>
      ),
    },
    // --- Cập nhật lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      width: 150,
      sorter: {
        compare: (a: ColumnTypes, b: ColumnTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ColumnTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật lúc
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      align: 'center',
      key: 'updatedAt',
      width: 150,
      sorter: {
        compare: (a: ColumnTypes, b: ColumnTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ColumnTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.actions'),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      width: 100,
      render: (_: string, data: ColumnTypes) => (
        <EyeOutlined onClick={() => navigate(`${ROUTE_PATHS.SOLUTION_SEARCH_DETAIL}?id=${data.id}`)} />
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('???', advancedFilter),
    [advancedFilter]
  );

  const formatData: ColumnTypes[] = useMemo(() => (solutionSearchData?.data?.map((val) => ({
    id: val.solutionSearchData.id,
    question: val.solutionSearchData.question,
    result: val.solutionSearchData.result,
    grade: val.solutionSearchData.grade,
    createdAt: val.solutionSearchData.createdAt,
    updatedAt: val.solutionSearchData.updatedAt,
  })) || []), [solutionSearchData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.solutionSearchManagement')}
      // rightHeader={(
      //   <Button
      //     type="primary"
      //     disabled={!roleCreate}
      //     onClick={() =>
      // navigate(`${ROUTE_PATHS.SOLUTION_SEARCH_DETAIL}?locale=${defaultWebsiteLanguage}`)}
      //   >
      //     <PlusOutlined />
      //     {t('system.create')}
      //   </Button>
      // )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'question', 'result', 'grade', 'createdAt', 'updatedAt', 'actions'],
            columns,
            pageData: formatData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: solutionSearchData?.meta.total,
            filterFields,
            noBaseCol: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
      <Modal
        open={!!activeResult}
        title={t('solutionSearch.resultDetail')}
        onCancel={() => setActiveResult('')}
        footer={null}
      >
        <Typography.Text>
          {activeResult}
        </Typography.Text>
      </Modal>
    </>
  );
};

export default SolutionSearchManagement;
