import {
  DashboardOutlined,
  FileOutlined, MenuOutlined,
  LayoutOutlined,
  SettingOutlined, CodeSandboxOutlined,
  ToolOutlined,
  BgColorsOutlined,
  ProjectOutlined,
  UsergroupAddOutlined,
  RiseOutlined,
  FundOutlined,
  BoxPlotOutlined,
  LineHeightOutlined,
  ContainerOutlined,
  GiftOutlined,
  SlidersOutlined,
  OrderedListOutlined,
  FunctionOutlined,
  RadarChartOutlined
} from '@ant-design/icons';

import roles from './roles';

import { MenuItem } from 'common/components/Sidebar';
import { ROUTE_PATHS } from 'common/utils/constant';

const menuSidebar: MenuItem[] = [
  {
    key: 'dashboard',
    title: 'sidebar.dashboard',
    path: '/',
    icon: <DashboardOutlined />,
  },
  {
    key: 'page_management',
    title: 'sidebar.page',
    icon: <CodeSandboxOutlined />,
    items: [
      {
        key: 'page_list',
        title: 'sidebar.pageList',
        path: ROUTE_PATHS.PAGE_MANAGEMENT,
        role: roles.PAGE_INDEX,
        childrens: [ROUTE_PATHS.PAGE_DETAIL],
      },
      {
        key: 'page_template',
        title: 'sidebar.pageTemplate',
        path: ROUTE_PATHS.PAGE_TEMPLATE_MANAGEMENT,
        role: roles.TEMPLATE_INDEX,
      },
    ],
  },
  // {
  //   key: 'news',
  //   title: 'sidebar.news',
  //   icon: <ContainerOutlined />,
  //   items: [
  //     {
  //       key: 'news_management',
  //       title: 'sidebar.newsList',
  //       path: ROUTE_PATHS.NEWS_MANAGEMENT,
  //       role: roles.NEWS_INDEX,
  //       childrens: [ROUTE_PATHS.NEWS_DETAIL]
  //     },
  //     {
  //       key: 'news_category',
  //       title: 'sidebar.newsCategory',
  //       path: ROUTE_PATHS.NEWS_CATEGORY_MANAGEMENT,
  //       role: roles.NEWS_CATE_INDEX,
  //       childrens: [ROUTE_PATHS.NEWS_CATEGORY_DETAIL]
  //     },
  //   ],
  // },
  {
    key: 'file',
    title: 'sidebar.file',
    icon: <FileOutlined />,
    path: ROUTE_PATHS.FILE_MANAGEMENT,
    role: roles.FOLDER_VIEWALL,
  },
  {
    key: 'menu',
    title: 'sidebar.menu',
    icon: <MenuOutlined />,
    path: ROUTE_PATHS.MENU_MANAGEMENT,
    role: roles.MENU_INDEX,
    childrens: [ROUTE_PATHS.MENU_DETAIL],
  },
  {
    key: 'banner',
    title: 'sidebar.banner',
    icon: <LayoutOutlined />,
    path: ROUTE_PATHS.BANNER_MANAGEMENT,
    role: roles.BANNER_INDEX,
    childrens: [ROUTE_PATHS.BANNER_DETAIL],
  },
  // {
  //   key: 'static_block',
  //   title: 'sidebar.staticBlocks',
  //   icon: <ProjectOutlined />,
  //   path: ROUTE_PATHS.STATIC_BLOCK_MANAGEMENT,
  //   role: roles.STATIC_BLOCK_INDEX,
  //   childrens: [ROUTE_PATHS.STATIC_BLOCK_DETAIL]
  // },
  {
    key: 'students',
    title: 'sidebar.students',
    icon: <UsergroupAddOutlined />,
    path: ROUTE_PATHS.STUDENTS_MANAGEMENT,
    role: roles.STUDENTS_INDEX,
    childrens: [ROUTE_PATHS.STUDENTS_DETAIL]
  },
  {
    key: 'studentRole',
    title: 'sidebar.studentRole',
    icon: <RadarChartOutlined />,
    path: ROUTE_PATHS.STUDENTS_ROLE_MANAGEMENT,
    role: roles.STUDENT_ROLE_INDEX,
    childrens: [ROUTE_PATHS.STUDENTS_ROLE_DETAIL]
  },
  {
    key: 'grade',
    title: 'sidebar.grade',
    icon: <OrderedListOutlined />,
    path: ROUTE_PATHS.GRADE_MANAGEMENT,
    role: roles.GRADE_INDEX,
    childrens: [ROUTE_PATHS.GRADE_DETAIL]
  },
  {
    key: 'functional',
    title: 'sidebar.functional',
    icon: <FunctionOutlined />,
    path: ROUTE_PATHS.FUNCTIONAL_MANAGEMENT,
    role: roles.FUNCTIONAL_INDEX,
    childrens: [ROUTE_PATHS.FUNCTIONAL_DETAIL]
  },
  {
    key: 'orders',
    title: 'sidebar.orders',
    icon: <LineHeightOutlined />,
    path: ROUTE_PATHS.ORDERS_MANAGEMENT,
    role: roles.ORDERS_INDEX,
    childrens: [ROUTE_PATHS.ORDERS_DETAIL],
  },
  {
    key: 'featureHistories',
    title: 'sidebar.featureHistories',
    icon: <FundOutlined />,
    path: ROUTE_PATHS.FEATURE_HISTORY_MANAGEMENT,
    role: roles.FEATURE_HISTORY_INDEX
  },
  {
    key: 'solution_search',
    title: 'sidebar.solutionSearchManagement',
    icon: <ProjectOutlined />,
    path: ROUTE_PATHS.SOLUTION_SEARCH_MANAGEMENT,
    role: roles.SOLUTION_SEARCH_INDEX,
    childrens: [ROUTE_PATHS.SOLUTION_SEARCH_DETAIL],
  },
  {
    key: 'userLevels',
    title: 'sidebar.userLevels',
    icon: <RiseOutlined />,
    path: ROUTE_PATHS.USER_LEVEL_MANAGEMENT,
    role: roles.USER_LEVEL_INDEX,
    childrens: [ROUTE_PATHS.USER_LEVEL_DETAIL],
  },
  {
    key: 'onBoarding',
    title: 'sidebar.onBoarding',
    icon: <SlidersOutlined />,
    path: ROUTE_PATHS.ON_BOARDING_MANAGEMENT,
    role: roles.ON_BOARDING_INDEX,
    childrens: [ROUTE_PATHS.ON_BOARDING_DETAIL],
  },
  {
    key: 'products',
    title: 'sidebar.products',
    icon: <BoxPlotOutlined />,
    path: ROUTE_PATHS.PRODUCTS_MANAGEMENT,
    role: roles.PRODUCTS_INDEX,
    childrens: [ROUTE_PATHS.PRODUCTS_DETAIL],
  },
  {
    key: 'redeem',
    title: 'sidebar.redeem',
    icon: <GiftOutlined />,
    path: ROUTE_PATHS.REDEEM_MANAGEMENT,
    role: roles.REDEEM_INDEX,
    childrens: [ROUTE_PATHS.REDEEM_DETAIL],
  },
  {
    key: 'usageHistories',
    title: 'sidebar.usageHistories',
    icon: <BoxPlotOutlined />,
    path: ROUTE_PATHS.USAGE_HISTORIES_MANAGEMENT,
    role: roles.USAGE_HISTORIES_INDEX,
  },
  {
    key: 'faq',
    title: 'sidebar.faqs',
    icon: <ContainerOutlined />,
    items: [
      {
        key: 'faq_management',
        title: 'sidebar.faqsList',
        path: ROUTE_PATHS.FAQ_MANAGEMENT,
        role: roles.FAQ_INDEX,
        childrens: [ROUTE_PATHS.FAQ_DETAIL]
      },
      {
        key: 'faq_category_management',
        title: 'sidebar.faqCategory',
        path: ROUTE_PATHS.FAQ_CATEGORY_MANAGEMENT,
        role: roles.FAQ_CATEGORY_INDEX,
        childrens: [ROUTE_PATHS.FAQ_CATEGORY_DETAIL]
      },
    ],
  },
  // {
  //   key: 'contact',
  //   title: 'sidebar.contact',
  //   icon: <ContactsOutlined />,
  //   items: [
  //     {
  //       key: 'contact_management',
  //       title: 'sidebar.contactList',
  //       path: ROUTE_PATHS.CONTACT_MANAGEMENT,
  //       role: roles.CONTACT_INDEX
  //     },
  //     {
  //       key: 'contact_problem',
  //       title: 'sidebar.contactProblem',
  //       path: ROUTE_PATHS.CONTACT_PROBLEM_MANAGEMENT,
  //       role: roles.CONTACT_PROBLEM_INDEX,
  //       childrens: [ROUTE_PATHS.CONTACT_PROBLEM_DETAIL]
  //     },
  //   ],
  // },
  // {
  //   key: 'email_template',
  //   title: 'sidebar.emailTemplate',
  //   icon: <MailOutlined />,
  //   path: ROUTE_PATHS.EMAIL_TEMPLATE_MANAGEMENT,
  //   role: roles.EMAIL_TEMPLATE_INDEX,
  //   childrens: [ROUTE_PATHS.EMAIL_TEMPLATE_DETAIL]
  // },
  // {
  //   key: 'moduleForm',
  //   title: 'sidebar.formsModule',
  //   icon: <FormOutlined />,
  //   items: [
  //     {
  //       key: 'forms',
  //       title: 'sidebar.forms',
  //       path: ROUTE_PATHS.FORM_MANAGEMENT,
  //       role: roles.FORM_INDEX,
  //       childrens: [ROUTE_PATHS.FORM_DETAIL],
  //     },
  //     {
  //       key: 'submit_form',
  //       title: 'sidebar.typeFormSubmit',
  //       path: ROUTE_PATHS.SUBMIT_FORM_MANAGEMENT,
  //       role: roles.SUBMIT_FROM,
  //       childrens: [ROUTE_PATHS.SUBMIT_FORM_LIST_BY_FORM]
  //     },
  //   ]
  // },
  {
    key: 'tools',
    title: 'sidebar.tools',
    icon: <ToolOutlined />,
    items: [
      {
        key: 'fe_translation',
        title: 'sidebar.feTranslations',
        path: ROUTE_PATHS.FE_TRANSLATION_MANAGEMENT,
        role: roles.FE_TRANSLATION_INDEX,
      },
      {
        key: 'cms_translation',
        title: 'sidebar.cmsTranslations',
        path: ROUTE_PATHS.CMS_TRANSLATION_MANAGEMENT,
        role: roles.CMS_TRANSLATION_INDEX,
      },
      {
        key: 'redirect',
        title: 'sidebar.redirect',
        path: ROUTE_PATHS.REDIRECT_MANAGEMENT,
        role: roles.REDIRECT_INDEX,
      },
    ],
  },
  {
    key: 'appearance',
    title: 'sidebar.appearance',
    icon: <BgColorsOutlined />,
    items: [
      {
        key: 'config-management',
        title: 'sidebar.headerFooter',
        path: ROUTE_PATHS.SYSTEM_CONFIG_MANAGEMENT,
        role: roles.CONFIG_HEADER_INDEX || roles.CONFIG_FOOTER_INDEX,
      },
      {
        key: 'errors_management',
        title: 'sidebar.errorsManagement',
        path: ROUTE_PATHS.ERRORS_MANAGEMENT,
      },
    ],
  },
  {
    key: 'system',
    title: 'sidebar.system',
    icon: <SettingOutlined />,
    items: [
      {
        key: 'system_management',
        title: 'sidebar.general',
        path: ROUTE_PATHS.SYSTEM_MANAGEMENT,
        role: roles.SYSTEM_INDEX,
      },
      {
        key: 'user_management',
        title: 'sidebar.users',
        path: ROUTE_PATHS.USERS_MANAGEMENT,
        role: '*',
      },
      {
        key: 'roles',
        title: 'sidebar.roles',
        path: ROUTE_PATHS.ROLES_MANAGEMENT,
        role: '*',
      },
      {
        key: 'activity_logs',
        title: 'sidebar.activityLogs',
        path: ROUTE_PATHS.ACTIVITY_MANAGEMENT,
        role: roles.ACTIVITYLOG_INDEX,
      },
    ],
  },
];

export default menuSidebar;
