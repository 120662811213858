import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Spin, Switch, Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller, FormProvider, useFieldArray, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  createUserLevelService,
  getAllUserLevelService,
  getUserLevelService,
  updateUserLevelService
} from 'common/services/extends/userLevels';
import { CreateUserLevelParams, UpdateUserLevelParams, UserLevelConfig } from 'common/services/extends/userLevels/types';
import { funtionsOptions, ROUTE_PATHS, userLevelOptions } from 'common/utils/constant';
import { userLevelFormSchema } from 'common/utils/schemas';

type UserLevelFormData = {
  name: string;
  requiredLogin: boolean;
  level: number;
  config: UserLevelConfig[];
};

const DEFAULT_VALUES = {
  name: '',
  requiredLogin: false,
  level: 4,
  config: funtionsOptions.map((item) => ({
    type: item.value,
    appOrMonthLimit: undefined,
    webOrWeekLimit: undefined
  } as UserLevelConfig))
};

const UserLevelDetail: React.FC<ActiveRoles> = ({
  roleCreate,
  roleIndex,
  roleUpdate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { defaultWebsiteLanguage } = useAppSelector((state) => state.system);

  const queryClient = useQueryClient();

  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  const idParams = searchParams.get('id');
  const [currentLang] = useState<string>(
    localeParams
  );
  const method = useForm<UserLevelFormData>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(userLevelFormSchema),
    mode: 'onChange'
  });
  const { fields, append, remove } = useFieldArray({
    control: method.control,
    name: 'config',
  });

  const queryUserLevelById = ['getUserLevelDataId', { idParams }];

  const { data: userData, isLoading: userDataLoading } = useQuery(
    queryUserLevelById,
    () => {
      if (idParams) {
        return getUserLevelService(idParams);
      }
      return undefined;
    },
    {
      enabled: roleIndex && !!idParams
    }
  );

  const { data: listData } = useQuery(
    ['userLevelManagement-list'],
    () => getAllUserLevelService({ })
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['user-level-create'],
    async (params: CreateUserLevelParams) => createUserLevelService(params),
    {
      onSuccess: () => {
        navigate(`${ROUTE_PATHS.USER_LEVEL_MANAGEMENT}`);
      },
      onError: () => {
        message.error('"Không thể tạo mã kích hoạt vào lúc này do lỗi hệ thống. Vui lòng thử lại sau."');
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['user-level-update'],
    async (params: UpdateUserLevelParams) => updateUserLevelService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryUserLevelById);
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }

    }
  );
  const onSubmit = async () => {
    const formData = method.getValues();
    if (idParams) {
      updateMutate({
        id: idParams,
        name: formData.name,
        requiredLogin: formData.requiredLogin,
        level: formData.level,
        config: formData.config,
      });
    } else {
      createMutate({
        name: formData.name,
        requiredLogin: formData.requiredLogin,
        level: formData.level,
        config: formData.config,
      });
    }
  };
  useEffect(() => {
    if (userData) {
      method.reset({
        name: userData.userLevelData.name,
        requiredLogin: userData.userLevelData.requiredLogin,
        level: userData.userLevelData.level,
        config: userData.userLevelData.config
      });
    } else {
      method.reset(DEFAULT_VALUES);
    }
  }, [method, userData]);

  const filteredLevel = useMemo(() => {
    if (listData?.data && listData?.data.length > 0) {
      return listData?.data.map((item) => item.userLevelData.level || 0);
    }
    return [];
  }, [listData?.data]);

  const updatedUserLevelOptions = useMemo(() => userLevelOptions.map(
    (option) => (filteredLevel.includes(option.value as number)
    ? { ...option, disabled: true } : option)
  ), [filteredLevel]);

  return (
    <FormProvider<UserLevelFormData> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('userLevels.editUserLevel') : t('userLevels.createUserLevel')}
        rightHeader={(
          <Button
            type="primary"
            loading={userDataLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={userDataLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <div className="u-mt-16">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('userLevels.name')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        defaultValue=""
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="name"
                            placeholder={t('system.input') + t('userLevels.name')}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('userLevels.requiredLogin')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <div className="u-mt-16">
                        <Controller
                          name="requiredLogin"
                          defaultValue={false}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <>
                              <Switch
                                checked={value}
                                onChange={onChange}
                              />
                              {error && (
                                <span
                                  className="a-input_errorMessage"
                                >
                                  {error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="u-mt-16">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('userLevels.level')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="level"
                        defaultValue=""
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={updatedUserLevelOptions}
                              placeholder={`${t('system.select')} ${t('userLevels.level')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="u-mt-16">
                  <Card title={t('userLevels.config')}>
                    {fields.map((item, index) => (
                      <Card
                        key={`userLevelConfig-${item.id}`}
                        className="u-mt-16"
                        extra={(
                          <Button
                            type="text"
                            onClick={() => remove(index)}
                            icon={(<DeleteOutlined />)}
                          />
                        )}
                      >
                        <Row gutter={16}>
                          <Col span={8}>
                            <Typography.Text strong>
                              {t('userLevels.fnType')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`config.${index}.type`}
                              defaultValue=""
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <>
                                  <DropdownElement
                                    options={funtionsOptions}
                                    placeholder={`${t('system.select')} ${t('userLevels.fnType')}`}
                                    locale={currentLang}
                                    value={value}
                                    size="large"
                                    onChange={onChange}
                                  />
                                  {error && (
                                    <span
                                      className="a-input_errorMessage"
                                    >
                                      {error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />
                          </Col>
                          <Col span={8}>
                            <Typography.Text strong>
                              {t(method.watch('level') === 4 ? 'userLevels.limitOnWeb' : 'userLevels.limitPerWeek')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`config.${index}.webOrWeekLimit`}
                              defaultValue=""
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  name="name"
                                  type="number"
                                  placeholder={`${t('system.input')} ${t(method.watch('level') === 4 ? 'userLevels.limitOnWeb' : 'userLevels.limitPerWeek')}`}
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                />
                              )}
                            />
                          </Col>
                          <Col span={8}>
                            <Typography.Text strong>
                              {t(method.watch('level') === 4 ? 'userLevels.limitOnApp' : 'userLevels.limitPerMonth')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`config.${index}.appOrMonthLimit`}
                              defaultValue=""
                              render={({
                                field: { value, onChange },
                                fieldState: { error },
                              }) => (
                                <Input
                                  className="u-mt-8"
                                  name="name"
                                  type="number"
                                  placeholder={`${t('system.input')} ${t(method.watch('level') === 4 ? 'userLevels.limitOnApp' : 'userLevels.limitPerMonth')}`}
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Card>
                    ))}
                    <Button
                      className="u-mt-32 btn-center"
                      type="primary"
                      onClick={() => append({})}
                    >
                      {t('userLevels.addConfig')}
                    </Button>
                  </Card>
                </div>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={userData?.userLevelData ? dayjs(userData.userLevelData.createdAt).fromNow() : ''}
                createdBy="Admin"
                lastUpdated={userData?.userLevelData ? dayjs(userData.userLevelData.updatedAt).fromNow() : ''}
                lastUpdatedBy="Admin"
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default UserLevelDetail;
