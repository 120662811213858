import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter,
} from 'common/components/PageTable/filter';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { getAllProductsService } from 'common/services/extends/products';
import { ProductItemData } from 'common/services/extends/products/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type ProductListDataTypes = {
  name: string;
  updaterData?: {
    email?: string,
    id: number,
    name: string,
  }
} & ProductItemData;

const ProductsManagement: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    system: { defaultPageSize, advancedFilter, defaultWebsiteLanguage },
  } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Variables */
  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('product', advancedFilter),
    [advancedFilter]
  );

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { data: productsData, isLoading } = useQuery(
    ['getProductsService', keyword, currentPage, currentView, selectedFilterList],
    () => getAllProductsService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage,
    }
  );

  /* Datas */
  const tableData: ProductListDataTypes[] = useMemo(
    () => productsData?.data.map((item) => ({
      id: item.productData.id,
      name: defaultWebsiteLanguage ? (item.translations[defaultWebsiteLanguage]?.name || '') : '',
      nameUnique: item.productData.nameUnique,
      duration: item.productData.duration,
      productAppleId: item.productData.productAppleId,
      productGoogleId: item.productData.productGoogleId,
      userLevel: item.productData.userLevel,
      displayOrder: item.productData.displayOrder,
      contentColor: item.productData.contentColor,
      status: item.productData.status,
      createdAt: item.productData.createdAt,
      updatedAt: item.productData.updatedAt,
      updaterData: item.updaterData,
      locale: Object.fromEntries(
        Object.entries(item.translations)
          .map(([k, o]) => [k, { ...o, id: item.translations[k].id },
          ])
      ),
      showOnDevice: item.productData.showOnDevice
    })) || [],
    [productsData, defaultWebsiteLanguage]
  );

  const columns: ColumnsType<ProductListDataTypes> = useMemo(
    () => [
      // --- ID
      {
        title: 'ID',
        key: 'id',
        width: 55,
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.id}</Typography.Text>
        ),
      },
      {
        title: t('product.name'),
        dataIndex: 'name',
        key: 'name',
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.name}</Typography.Text>
        ),
      },
      {
        title: t('product.duration'),
        dataIndex: 'duration',
        key: 'duration',
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.duration}</Typography.Text>
        ),
      },
      {
        title: t('product.userLevel'),
        dataIndex: 'userLevel',
        key: 'userLevel',
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.userLevel?.name || ''}</Typography.Text>
        ),
      },
      {
        title: t('product.displayOrder'),
        dataIndex: 'displayOrder',
        key: 'displayOrder',
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.displayOrder}</Typography.Text>
        ),
      },
      {
        title: t('product.status'),
        dataIndex: 'status',
        key: 'status',
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.status ? t('product.active') : t('product.inactive')}</Typography.Text>
        ),
      },
      {
        title: t('system.updatedBy'),
        dataIndex: 'updatedBy',
        key: 'updatedBy',
        width: 100,
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{data.updaterData?.name}</Typography.Text>
        ),
      },
      // --- Tạo lúc
      {
        title: t('system.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 150,
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
      // --- Cập nhật
      {
        title: t('system.updatedAt'),
        dataIndex: 'updatedAt',
        width: 150,
        key: 'updatedAt',
        render: (_name: string, data: ProductListDataTypes) => (
          <Typography.Text>{formatDateTime(data.updatedAt)}</Typography.Text>
        ),
      }
    ],
    [t, navigateParams]
  );

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams(
      {
        ...mappingFilterToQuery(selectedFilterList),
        page: '1',
      },
      { replace: true }
    );
  }, [keyword, setSearchParams, selectedFilterList]);

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.products')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => {
                navigate(`${ROUTE_PATHS.PRODUCTS_DETAIL}`);
              }}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleEditPage={(id, _, language) => navigateParams(`${ROUTE_PATHS.PRODUCTS_DETAIL}`, `id=${id}&locale=${language}`)}
          handleCreatePage={(id, _, language) => navigateParams(`${ROUTE_PATHS.PRODUCTS_DETAIL}`, `id=${id}&locale=${language}`)}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'duration', 'userLevel', 'displayOrder', 'status', 'updatedBy', 'updatedAt'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: productsData?.meta.total || 1,
            noDeleteLanguage: true,
            filterFields
          }}
          roles={{ roleCreate, roleUpdate }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default ProductsManagement;
