import { GetStudentByIdParamsTypes, StudentsTypes } from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllStudentsService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<StudentsTypes[]>> => {
  const res = await axiosInstance.get('students', { params });
  return res.data;
};

export const getStudentByIdService = async ({ id }: GetStudentByIdParamsTypes)
  : Promise<StudentsTypes> => {
  const res = await axiosInstance.get(`students/${id}`);
  return res.data.data;
};
