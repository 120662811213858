import { CreateStudentRoleParams, StudentRoleTypes, UpdateStudentRoleParams } from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllStudentRoleService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<StudentRoleTypes[]>> => {
  const res = await axiosInstance.get('student-roles', { params });
  return res.data;
};

export const createStudentRoleService = async (params: CreateStudentRoleParams): Promise<void> => {
  await axiosInstance.post('student-roles', params);
};

export const updateStudentRoleService = async (params: UpdateStudentRoleParams): Promise<void> => {
  await axiosInstance.put(`student-roles/${params.id}`, params);
};

export const deleteStudentRoleService = async (
  ids: number[],
): Promise<void> => {
  await axiosInstance.delete('student-roles', { data: { ids } });
};

export const deleteStudentRoleTranslationService = async (
  params: { ids: number[] },
): Promise<void> => {
  await axiosInstance.delete('student-roles/translations', { data: params });
};

export const getStudentRoleByIdService = async (id: number): Promise<StudentRoleTypes> => {
  const res = await axiosInstance.get(`student-roles/${id}`);
  return res.data.data;
};
