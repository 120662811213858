import { ExportResponse, OrderData } from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllOrdersService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<OrderData[]>> => {
  const res = await axiosInstance.get('orders', { params });
  return res.data;
};

export const getOrderByIdService = async (id: number): Promise<OrderData> => {
  const res = await axiosInstance.get(`orders/${id}`);
  return res.data.data;
};

export const exportOrdersService = async (params?: BaseFilterParams): Promise<ExportResponse> => {
  const res = await axiosInstance.get('orders/exports', { params });
  return res.data.data;
};
