import { Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import useDebounce from 'common/hooks/useDebounce';
import useScrollInfinite from 'common/hooks/useScrollInfinite';
import { getCountriesListService } from 'common/services/locations';

export type DropdownLocationProps = {
  options?: OptionType[];
  loading?: boolean;
  name?: string;
  locale?: string;
  isValueSlug?: boolean;
  placeholder?: string;
  size?: SizeType;
  value: any;
  onChange: (...event: any[]) => void;
  filterParams?: string;
  isGetOption?: boolean;
  hasAllOption?: boolean;
  isShowSearch?: boolean;
  multiple?: {
    allowClear?: boolean;
    defaultValue?: any;
  },
  error?: string;
  ids?: string;
  slugs?: string;
  labelInValue?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

// export function getDropdownTypeKey(value: string) {
//   return Object.entries(DropDownType).find(([, val]) => val === value)?.[0];
// }

export const DropdownLocation: React.FC<DropdownLocationProps> = ({
  name,
  options,
  size,
  locale,
  isValueSlug,
  placeholder,
  isShowSearch = true,
  value,
  onChange,
  filterParams,
  hasAllOption,
  isGetOption,
  multiple,
  error,
  ids,
  slugs,
  labelInValue = false,
  disabled,
  readOnly
}) => {
  const [txtSearch, setTxtSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState('');
  const [optionsData, setOptionsData] = useState<OptionType[]>(options || []);

  useDebounce(() => {
    setSearchDebounce(txtSearch);
  }, 500, [txtSearch]);

  const {
    data: dropdownList,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    [`dropdownLocation}${name ? `-${name}` : ''}`, {
      locale, searchDebounce, ids, slugs
    }],
    ({ pageParam = 1 }) => getCountriesListService({
      keyword: searchDebounce,
      limit: 10,
      page: pageParam,
      ids: ids?.toString(),
      slugs
    }),
    {
      getNextPageParam: (lastPage) => (lastPage.meta.page + 1 <= lastPage.meta.totalPages
        ? lastPage.meta.page + 1
        : undefined),
    }
  );

  const { setNode } = useScrollInfinite(() => {
    fetchNextPage();
  });

  useEffect(() => {
    if (dropdownList) {
      const data = dropdownList.pages.reduce((prev: OptionType[], current) => [...prev,
      ...current.data.map((val) => ({
        label: `${val.countryData.code} - ${val.countryData.name}`,
        value: val.countryData.id,
        currency: val.countryData.currency || '',
      })).filter((val) => ((filterParams ? val.value.toString() !== filterParams : true)))], []);
      setOptionsData((hasAllOption && !searchDebounce) ? [{ label: 'Tất cả', value: undefined }, ...data] : data);
    }
  }, [dropdownList, filterParams, hasAllOption, isValueSlug, searchDebounce]);

  useEffect(() => {
    if (options) {
      setOptionsData(options.filter((
        item
      ) => item.label.toLowerCase().includes(searchDebounce.toLowerCase())));
    }
  }, [options, searchDebounce]);

  return (
    <>
      <Select
        className={`u-mt-8${readOnly ? ' readonly' : ''}`}
        disabled={disabled || readOnly}
        size={size}
        style={{ width: '100%' }}
        placeholder={placeholder}
        value={value}
        labelInValue={labelInValue}
        onChange={(val) => {
          if (isGetOption) {
            if (multiple) {
              const selected = optionsData.filter((item) => val.includes(item.value));
              onChange(selected);
            } else if (val) {
              onChange(optionsData.find((item) => item.value === val));
            } else {
              onChange({
                value: undefined,
                label: undefined
              });
            }
          } else {
            onChange(val);
          }
          if (multiple) {
            setTxtSearch('');
          }
        }}
        {...multiple && {
          mode: 'multiple',
          allowClear: multiple.allowClear,
          defaultValue: multiple.defaultValue,
        }}
        dropdownMatchSelectWidth={false}
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultActiveFirstOption={false}
        showSearch={isShowSearch}
        filterOption={false}
        onSearch={(val) => isShowSearch && setTxtSearch(val)}
        onClear={() => setTxtSearch('')}
        allowClear
        loading={isLoading}
      >
        {
          optionsData.map(
            (option, idx) => (idx === optionsData.length - 3 ? (
              <Select.Option label={option.label} value={option.value} key={`option-${idx.toString()}`}>
                <div ref={(ref) => setNode(ref)}>{option.label}</div>
              </Select.Option>
            ) : (
              <Select.Option label={option.label} value={option.value} key={`option-${idx.toString()}`}>
                {option.label}
              </Select.Option>
            ))
          )
        }
      </Select>
      {error && (
        <span
          className="a-input_errorMessage"
        >
          {error}
        </span>
      )}
    </>
  );
};
