import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col,
  message, Row,
  Spin,
  Switch,
  Typography
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Controller, FormProvider,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { getFunctionalByIdservice, updateFunctionalByIdservice } from 'common/services/extends/functional';
import { UpdateFunctionalParams } from 'common/services/extends/functional/types';
import { functionalFormSchema } from 'common/utils/schemas';

type FunctionalFormTypes = {
  name: string
  title1?: string
  title2?: string
  description1?: string
  description2?: string
  active: boolean
};

const DEFAULT_VALUES: FunctionalFormTypes = {
  name: '',
  active: false,
  description1: '',
  description2: '',
  title1: '',
  title2: ''
};

const FunctionalDetail: React.FC<ActiveRoles> = ({
  roleIndex,
  roleUpdate
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { defaultWebsiteLanguage } = useAppSelector((state) => state.system);

  const queryClient = useQueryClient();

  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  const idParams = searchParams.get('id');
  const [currentLang] = useState<string>(
    localeParams
  );
  const method = useForm<FunctionalFormTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(functionalFormSchema),
    mode: 'onChange'
  });

  const queryFunctionalDataById = ['getFunctionalDataById', { idParams }];

  const { data: functionalData, isLoading: functionalDataLoading } = useQuery(
    queryFunctionalDataById,
    () => {
      if (idParams) {
        return getFunctionalByIdservice(idParams);
      }
      return undefined;
    },
    {
      enabled: roleIndex && !!idParams
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['grade-update'],
    async (params: UpdateFunctionalParams) => updateFunctionalByIdservice(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryFunctionalDataById);
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }

    }
  );
  const onSubmit = async () => {
    const formData = method.getValues();
    const dataRequest: UpdateFunctionalParams = {
      active: formData.active,
      translations: {
        [currentLang]: {
          functionalData: {
            name: formData.name,
            description1: formData.description1,
            description2: formData.description2,
            title1: formData.title1,
            title2: formData.title2
          }
        }
      }
    };
    if (idParams) {
      updateMutate({
        id: idParams,
        ...dataRequest
      });
    }
  };

  useEffect(() => {
    if (functionalData) {
      method.reset({
        active: functionalData.functionalData.active,
        name: functionalData.translations[currentLang]?.name || '',
        title1: functionalData.translations[currentLang]?.title1 || '',
        title2: functionalData.translations[currentLang]?.title2 || '',
        description1: functionalData.translations[currentLang]?.description1 || '',
        description2: functionalData.translations[currentLang]?.description2 || '',
      });
    } else {
      method.reset(DEFAULT_VALUES);
    }
  }, [method, functionalData, currentLang]);

  return (
    <FormProvider<FunctionalFormTypes> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('grade.editGrade') : t('functional.createGrade')}
        rightHeader={(
          <Button
            type="primary"
            loading={functionalDataLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={!roleUpdate}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={functionalDataLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <div className="u-mt-16">
                  <Row gutter={16}>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('functional.name')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="name"
                            placeholder={`${t('system.input')} ${t('functional.name')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('functional.statusFunctional')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <div className="u-mt-16">
                        <Controller
                          name="active"
                          defaultValue={false}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <>
                              <Switch
                                checked={value}
                                onChange={onChange}
                              />
                              {error && (
                                <span
                                  className="a-input_errorMessage"
                                >
                                  {error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={24} className="u-mb-12">
                      <Typography.Text strong>
                        {t('functional.title1')}
                      </Typography.Text>
                      <Controller
                        name="title1"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="title1"
                            placeholder={`${t('system.input')} ${t('functional.title1')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={24} className="u-mb-12">
                      <Typography.Text strong>
                        {t('functional.description1')}
                      </Typography.Text>
                      <Controller
                        name="description1"
                        render={({
                          field: { value, onChange },
                          fieldState
                        }) => (
                          <div className="u-mt-8">
                            <TextArea value={value || ''} onChange={onChange} rows={5} />
                            {fieldState.error && (
                              <span className="a-input_errorMessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col span={24} className="u-mb-12">
                      <Typography.Text strong>
                        {t('functional.title2')}
                      </Typography.Text>
                      <Controller
                        name="title2"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="title2"
                            placeholder={`${t('system.input')} ${t('functional.title2')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={24} className="u-mb-12">
                      <Typography.Text strong>
                        {t('functional.description2')}
                      </Typography.Text>
                      <Controller
                        name="description2"
                        render={({
                          field: { value, onChange },
                          fieldState
                        }) => (
                          <div className="u-mt-8">
                            <TextArea value={value || ''} onChange={onChange} rows={5} />
                            {fieldState.error && (
                              <span className="a-input_errorMessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={functionalData?.functionalData?.createdAt ? dayjs(functionalData.functionalData?.createdAt).fromNow() : ''}
                createdBy="Admin"
                lastUpdated={functionalData?.functionalData?.updatedAt ? dayjs(functionalData.functionalData?.updatedAt).fromNow() : ''}
                lastUpdatedBy="Admin"
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default FunctionalDetail;
