import { SolutionSearchDataTypes, SolutionSearchDetailTypes } from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllSolutionSearchService = async (params: BaseFilterParams):
  Promise<APIPaginationResponse<SolutionSearchDataTypes[]>> => {
  const res = await axiosInstance.get('/solution-search', { params });
  return res.data;
};

export const getSolutionSearchByIdService = async (id: string):
  Promise<SolutionSearchDetailTypes> => {
  const res = await axiosInstance.get(`/solution-search/${id}`);
  return res.data.data;
};
