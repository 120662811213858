import {
  DeleteOutlined,
  EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, message, Modal, Space, Switch, Tooltip, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import UpdateContactModal from './DetailModal';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { changeRedeemStatusService, deleteRedeemService, getAllRedeemService } from 'common/services/extends/redeem';
import { RedeemDataTypes } from 'common/services/extends/redeem/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const RedeemManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate, roleDelete, roleIndex
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateParams = useNavigateParams();
  const queryClient = useQueryClient();

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [activeIdx, setActiveIdx] = useState<number>(-1);

  const queryKey = ['redeemManagement-list', currentPage, keyword, currentView];

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllRedeemService({ page: currentPage, keyword, limit: currentView }),
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deleteReedemMutate'],
    async (ids: number[]) => deleteRedeemService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const { mutate: changeStatusRedeemMutate, isLoading: changeStatusLoading } = useMutation(
    ['changeStatusRedeemMutate'],
    async (data:
      {
        id: number,
        status: number
      }) => changeRedeemStatusService(data.id, data.status),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<RedeemDataTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('redeem.campaign'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData.name}
        </Typography.Text>
      ),
    },
    // --- Email
    {
      title: t('redeem.type'),
      dataIndex: 'type',
      key: 'type',
      width: 240,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData.type === 1 ? t('redeem.singleUse') : t('redeem.multiUses')}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.active'),
      key: 'status',
      width: 240,
      align: 'center',
      render: (_name: string, data: RedeemDataTypes) => (
        <Switch
          disabled={changeStatusLoading}
          checked={data.redeemData.status === 1}
          onChange={() => changeStatusRedeemMutate({
            id: data.redeemData.id,
            status: data.redeemData.status === 1 ? 0 : 1
          })}
        />
      ),
    },
    {
      title: t('redeem.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.startDate)}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.endDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('redeem.quantityCode'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData?.quantity}
        </Typography.Text>
      ),
    },
    {
      title: t('usageHistories.quantityUsed'),
      dataIndex: 'quantityUsed',
      key: 'quantityUsed',
      width: 100,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData?.quantityUsed}
        </Typography.Text>
      ),
    },
    {
      title: t('redeem.product'),
      dataIndex: 'product',
      key: 'product',
      width: 150,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData?.product?.name}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      width: 250,
      key: 'createdAt',
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.updatedAt || '')}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: RedeemDataTypes) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              disabled={!roleUpdate}
              icon={<EditOutlined />}
              onClick={() => navigateParams(ROUTE_PATHS.REDEEM_DETAIL, `id=${_data.redeemData.id}`)}
            />
          </Tooltip>
          <Tooltip title="View">
            <Button
              disabled={!roleIndex}
              icon={<EyeOutlined />}
              onClick={() => setActiveIdx(_data.redeemData.id)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              disabled={!roleDelete || deleteLoading}
              loading={deleteLoading}
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  className: 't-pagetable_deleteRecordModal',
                  autoFocusButton: 'cancel',
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'primary',
                  },
                  okButtonProps: {
                    type: 'default',
                  },
                  title: t('message.confirmDeleteRecord'),
                  onOk: () => {
                    deleteMutate([_data.redeemData.id]);
                  },
                });
            }}
            />
          </Tooltip>
        </Space>
      ),
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [t, roleUpdate, roleDelete]);

  const tableData: RedeemDataTypes[] = useMemo(() => listData?.data || [], [listData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.redeem')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => navigate(ROUTE_PATHS.REDEEM_DETAIL)}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || deleteLoading || changeStatusLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'type', 'status', 'startDate', 'endDate', 'quantity', 'quantityUsed', 'product', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
      <UpdateContactModal
        id={activeIdx}
        handleClose={() => setActiveIdx(-1)}
      />
    </>
  );
};

export default RedeemManagement;
