import { Modal, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PriceDetailCountryData,
  ProductPriceDetailsData,
} from 'common/services/extends/products/types';

interface PriceListModalProps {
  data?: ProductPriceDetailsData;
  open: boolean;
  onClose: () => void;
}

const PriceListModal = ({ data, open, onClose }: PriceListModalProps) => {
  const { t } = useTranslation();

  const formattedData = useMemo(() => {
    const globalPrice = data?.productPriceData.price;
    const vietnamesePrice = data?.productPriceData.priceVND;
    const countryPrices = data?.countryData ? data?.countryData.map((item) => ({
      id: item.id,
      price: item.price,
      name: `${item.name}${item.currency ? `(${item.currency})` : ''}`
    })) : [];

    return [
      {
        id: 'Global',
        name: `${t('price.global')}${t('system.dollar')}`,
        price: globalPrice,
      },
      {
        id: 'Vietnamese',
        name: `${t('price.vietnamese')}${t('system.vnd')}`,
        price: vietnamesePrice,
      },
      ...countryPrices,
    ];
  }, [data, t]);

  const columns: ColumnsType<
    PriceDetailCountryData | { id: string; name: string; price: number | undefined }
  > = useMemo(
    () => [
      {
        // --- Country
        title: t('price.country'),
        key: 'country',
        render: (
          _name: string,
          _data: PriceDetailCountryData | { id: string; name: string; price: number | undefined }
        ) => <Typography.Text>{_data.name}</Typography.Text>,
      },
      {
        // --- Price
        title: t('price.price'),
        key: 'price',
        render: (
          _name: string,
          _data: PriceDetailCountryData | { id: string; name: string; price: number | undefined }
        ) => <Typography.Text>{_data.price}</Typography.Text>,
      },
    ],
    [t]
  );

  return (
    <Modal title={t('price.viewListModalTitle')} open={open} onCancel={onClose} footer={null}>
      <Table columns={columns} dataSource={formattedData} />
    </Modal>
  );
};
export default PriceListModal;
