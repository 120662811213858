import {
  CreateGradeParams,
  GradeDetailTypes,
  GradesDataTypes,
  UpdateGradeParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllGradesService = async (
  params?: BaseFilterParams,
): Promise<APIPaginationResponse<GradesDataTypes[]>> => {
  const res = await axiosInstance.get('grades', { params });
  return res.data;
};

export const getGradeService = async (
  id: string,
): Promise<GradeDetailTypes> => {
  const res = await axiosInstance.get(`grades/${id}`);
  return res.data.data;
};

export const updateGradeService = async (
  params: UpdateGradeParams,
): Promise<void> => {
  await axiosInstance.put(`grades/${params.id}`, params);
};

export const createGradeService = async (params: CreateGradeParams): Promise<void> => {
  await axiosInstance.post('grades', params);
};

export const deleteGradesService = async (
  ids: number[],
): Promise<void> => {
  await axiosInstance.delete('grades', { data: { ids } });
};

export const deleteGradeTranslationService = async (
  params: { ids: number[] },
): Promise<void> => {
  await axiosInstance.delete('grades/translations', { data: params });
};
