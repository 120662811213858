import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col,
  message, Row,
  Spin,
  Switch,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Controller, FormProvider,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import { createGradeService, getGradeService, updateGradeService } from 'common/services/extends/grade';
import { CreateGradeParams, UpdateGradeParams } from 'common/services/extends/grade/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { gradeFormSchema } from 'common/utils/schemas';

type GradeFormTypes = {
  name: string
  active: boolean
  displayOrder: number
  studentRoleId?: number
};

const DEFAULT_VALUES: GradeFormTypes = {
  name: '',
  active: false,
  displayOrder: 1,
  studentRoleId: undefined
};

const GradeDetail: React.FC<ActiveRoles> = ({
  roleIndex,
  roleUpdate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { defaultWebsiteLanguage, languageOptions } = useAppSelector((state) => state.system);

  const queryClient = useQueryClient();

  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  const idParams = searchParams.get('id');
  const [currentLang, setCurrentLang] = useState<string>(
    localeParams
  );
  const method = useForm<GradeFormTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(gradeFormSchema),
    mode: 'onChange'
  });

  const queryGradeDataById = ['getGradeDataById', { idParams }];

  const { data: gradeData, isLoading: gradeDataLoading } = useQuery(
    queryGradeDataById,
    () => {
      if (idParams) {
        return getGradeService(idParams);
      }
      return undefined;
    },
    {
      enabled: roleIndex && !!idParams
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['createGradeMutate'],
    async (params: CreateGradeParams) => createGradeService(params),
    {
      onSuccess: () => {
        navigate(ROUTE_PATHS.GRADE_MANAGEMENT);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['grade-update'],
    async (params: UpdateGradeParams) => updateGradeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryGradeDataById);
      },
      onError: () => {
        message.error('Đã có lỗi xảy ra! Vui lòng thử lại sau');
      }

    }
  );
  const onSubmit = async () => {
    const formData = method.getValues();
    const dataRequest: UpdateGradeParams = {
      active: formData.active,
      displayOrder: formData.displayOrder,
      ...(formData.studentRoleId && { studentRoleId: formData.studentRoleId }),
      translations: {
        [currentLang]: {
          gradeData: {
            name: formData.name
          }
        }
      }
    };
    if (idParams) {
      updateMutate({
        id: idParams,
        ...dataRequest
      });
    } else {
      createMutate(dataRequest);
    }
  };

  const handleChangeLang = (lang: LanguageCodeTypes) => {
    setCurrentLang(lang);

    if (idParams) {
      setSearchParams(
        {
          id: String(idParams),
          locale: lang,
        },
        { replace: true }
      );
    } else {
      setSearchParams(
        {
          locale: lang,
        },
        { replace: true }
      );
    }
  };

  useEffect(() => {
    if (gradeData) {
      method.reset({
        active: gradeData.gradeData.active,
        displayOrder: gradeData.gradeData.displayOrder,
        studentRoleId: gradeData?.gradeData?.studentRole?.id,
        name: gradeData.translations[currentLang]?.name || ''
      });
    } else {
      method.reset(DEFAULT_VALUES);
    }
  }, [method, gradeData, currentLang]);

  return (
    <FormProvider<GradeFormTypes> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('grade.editGrade') : t('grade.createGrade')}
        rightHeader={(
          <Button
            type="primary"
            loading={gradeDataLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={!roleUpdate}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={gradeDataLoading || createLoading || updateLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <div className="u-mt-16">
                  <Row gutter={16}>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('grade.name')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="name"
                            placeholder={`${t('system.input')} ${t('grade.name')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('grade.displayOrder')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="displayOrder"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            type="number"
                            name="displayOrder"
                            placeholder={t('system.input') + t('grade.displayOrder')}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('sidebar.studentRole')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="studentRoleId"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DropdownElement
                              type="studentRole"
                              placeholder={`${t('system.select')} ${t('sidebar.studentRole')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && (
                            <span
                              className="a-input_errorMessage"
                            >
                              {error.message}
                            </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('grade.statusGrade')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <div className="u-mt-16">
                        <Controller
                          name="active"
                          defaultValue={false}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <>
                              <Switch
                                checked={value}
                                onChange={onChange}
                              />
                              {error && (
                                <span
                                  className="a-input_errorMessage"
                                >
                                  {error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={gradeData?.gradeData?.createdAt ? dayjs(gradeData.gradeData?.createdAt).fromNow() : ''}
                createdBy="Admin"
                lastUpdated={gradeData?.gradeData?.updatedAt ? dayjs(gradeData.gradeData?.updatedAt).fromNow() : ''}
                lastUpdatedBy={gradeData?.updater?.name || ''}
                languageList={languageOptions}
                currentLang={currentLang}
                handleChangeLang={(value) => value && handleChangeLang(value as LanguageCodeTypes)}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default GradeDetail;
