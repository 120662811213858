export enum Grade {
  ELEMENTARY = 1,
  MIDDLE = 2,
  HIGHSCHOOL = 3,
  UNIVERSITY_OR_COLLEGE = 4,
  POSTGRADUATE = 5
}

export type SolutionSearchDataTypes = {
  solutionSearchData: {
    id: number
    result: string
    error: string | null
    grade: Grade | null
    question: string | null
    token: number | null
    createdAt: string
    updatedAt: string
  },
  student: {
    id: number
    name: string
  } | null
};

export type SolutionSearchDetailTypes = {
  solutionSearchData: SolutionSearchDataTypes['solutionSearchData'] & {
    images: string
    response: {
      token: number,
      result: string,
      question?: string,
      imageOcr?: {
        answer: string,
        question: string
      }
    }
  },
  student: SolutionSearchDataTypes['student']
};
