import axiosInstance from 'common/services/common/instance';
import {
  CreatePriceParams,
  CreateProductParams,
  PriceData,
  ProductData,
  ProductPriceDetailsData,
  ProductStatus,
  UpdateProductParams,
} from 'common/services/extends/products/types';

export const getProductPricesService = async (
  productId: string,
  params?: BaseFilterParams
): Promise<APIPaginationResponse<PriceData[]>> => {
  const res = await axiosInstance.get(`products/${productId}/prices`, { params });
  return res.data;
};

export const getProductPriceDetailsByIdService = async (
  productId: string,
  priceId: string
): Promise<ProductPriceDetailsData> => {
  const res = await axiosInstance.get(`products/${productId}/prices/${priceId}`);
  return res.data.data;
};

export const updateProductPriceByIdService = async (
  productId: string,
  priceId: string,
  params: CreatePriceParams
): Promise<void> => {
  const res = await axiosInstance.put(`products/${productId}/prices/${priceId}`, params);
  return res.data;
};

export const createProductPriceService = async (
  productId: string,
  params: CreatePriceParams
): Promise<void> => {
  const res = await axiosInstance.post(`products/${productId}/prices`, params);
  return res.data;
};

export const getAllProductsService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<ProductData[]>> => {
  const res = await axiosInstance.get('products', { params });
  return res.data;
};

export const getProductByIdService = async (id: string): Promise<ProductData> => {
  const res = await axiosInstance.get(`products/${id}`);
  return res.data.data;
};

export const createProductService = async (params: CreateProductParams): Promise<void> => {
  await axiosInstance.post('products', params);
};

export const updateProductService = async (params: UpdateProductParams): Promise<void> => {
  await axiosInstance.put(`products/${params.id}`, params);
};

export const updateProductStatusService = async (
  id: string,
  params: ProductStatus
): Promise<void> => {
  await axiosInstance.put(`products/${id}/update-status`, params);
};
