import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import useNavigateParams from 'common/hooks/useNavigateParams';
import {
  deleteGradesService,
  deleteGradeTranslationService,
  getAllGradesService
} from 'common/services/extends/grade';
import { GradesDataTypes } from 'common/services/extends/grade/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export interface GradeColumnData extends GradesDataTypes {
  locale: GradesDataTypes['translations'];
}

const GradeManagement: React.FC<ActiveRoles> = ({
  roleUpdate,
  roleCreate,
  roleDelete
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
    const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize, defaultWebsiteLanguage } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
    const [selectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const queryKey = ['gradeManagement-list', currentPage, keyword, currentView];

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllGradesService({ page: currentPage, keyword, limit: currentView }),
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deleteGradeMutate'],
    async (ids: number[]) => deleteGradesService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const {
    mutate: deleteGradeTranslationMutate,
    isLoading: deleteGradeTranslationLoading
  } = useMutation(
    ['deleteGradeTranslationMutate'],
    async (ids: number[]) => deleteGradeTranslationService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const onDelete = async (data: GradeColumnData[], code?: string) => {
    switch (code) {
      case 'allRow':
      case 'all': {
        deleteMutate(data.map((item) => item.gradeData.id));
        break;
      }
      default: {
        if (code) {
          const localeId = data?.[0].locale[code]?.id;
          if (localeId) {
            deleteGradeTranslationMutate([localeId]);
          }
        }
        break;
      }
    }
  };

  /* Datas */
  const columns: ColumnsType<GradeColumnData> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: GradeColumnData) => (
        <Typography.Text>
          {data.gradeData.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('grade.name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (_name: string, data: GradeColumnData) => (
        <Typography.Text>
          {data.translations.vi ? data.translations.vi.name : data.translations.en.name}
        </Typography.Text>
      ),
    },
    // --- Email
    {
      title: t('grade.displayOrder'),
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      width: 100,
      render: (_name: string, data: GradeColumnData) => (
        <Typography.Text>
          {data.gradeData.displayOrder}
        </Typography.Text>
      )
    },
    {
      title: t('grade.statusGrade'),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (_name: string, data: GradeColumnData) => (
        <Typography.Text>
          {data.gradeData.active ? t('grade.active') : t('grade.inactive')}
        </Typography.Text>
      )
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      width: 250,
      key: 'createdAt',
      render: (_name: string, data: GradeColumnData) => (
        <Typography.Text>
          {formatDateTime(data.gradeData?.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      render: (_name: string, data: GradeColumnData) => (
        <Typography.Text>
          {formatDateTime(data.gradeData?.updatedAt || '')}
        </Typography.Text>
      ),
    },
  ]), [t, defaultWebsiteLanguage]);

  const tableData: GradeColumnData[] = useMemo(() => (
    listData?.data.map((val) => ({
      ...val,
      id: val.gradeData.id,
      locale: Object.fromEntries(
        Object.entries(val.translations)
          .map(([k, o]) => [k, { ...o, id: val.translations[k].id },
          ])
      ),
  })) || []), [listData]);

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.grade')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => {
                navigation(ROUTE_PATHS.GRADE_DETAIL);
              }}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={onDelete}
          isLoading={listLoading || deleteLoading || deleteGradeTranslationLoading}
          handleSearch={handleSearch}
          handleEditPage={(id, _, language) => navigateParams(ROUTE_PATHS.GRADE_DETAIL, `id=${id}&locale=${language}`)}
          handleCreatePage={(id, _, language) => navigateParams(ROUTE_PATHS.GRADE_DETAIL, `id=${id}&locale=${language}`)}
          tableProps={{
            initShowColumns: ['id', 'name', 'displayOrder', 'status'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
          }}
          roles={{ roleCreate, roleUpdate, roleDelete }}
          filtersDataTable={{
            selectedFilterList,
          }}
        />
      </div>
    </>
  );
};

export default GradeManagement;
