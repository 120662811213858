import {
  DeleteOutlined, HolderOutlined, PlusOutlined, SaveOutlined
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col,
  message,
  Row, Spin, Switch, Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ColumnsType } from 'antd/es/table';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChromePicker, ColorResult } from 'react-color';
import {
  Controller, FormProvider, useFieldArray, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation,
  useQuery, useQueryClient
} from 'react-query';
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import PageTable from 'common/components/PageTable';
import {
  createProductService,
  getProductByIdService,
  getProductPriceDetailsByIdService,
  getProductPricesService,
  updateProductService
} from 'common/services/extends/products';
import {
  CreateProductParams,
  EProductShowOnDevice,
  ProductPriceData,
  UpdateProductParams
} from 'common/services/extends/products/types';
import {
  productDurations,
  ROUTE_PATHS,
  showOnDeviceOptions
} from 'common/utils/constant';
import { detectError, formatDateTime } from 'common/utils/functions';
import { productFormSchema } from 'common/utils/schemas';
import EditPricesModal from 'extends/ProductsDetail/EditPriceModal';
import PriceListModal from 'extends/ProductsDetail/PriceListModal';

type ProductFormData = {
  nameUnique: string;
  duration: number;
  userLevel: OptionType;
  displayOrder: number;
  contentColor: string;
  productAppleId?: string;
  productGoogleId?: string;
  status: boolean;
  showOnDevice: EProductShowOnDevice;
  translations: {
    [locale: string]: {
      productData: {
        name: string;
        description: {
          value: string
        }[];
      }
    };
  };
};

const ProductsDetail: React.FC<ActiveRoles> = ({
  roleIndex,
  roleCreate,
  roleUpdate
}) => {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { languageOptions, defaultWebsiteLanguage } = useAppSelector((state) => state.system);
  const queryClient = useQueryClient();

  const localeParam = searchParams.get('locale') || defaultWebsiteLanguage || '';
  const idParam = searchParams.get('id');

  const {
    system: { defaultPageSize },
  } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [currentLang, setCurrentLang] = useState<string>(localeParam);
  const [currPriceData, setCurrPriceData] = useState<ProductPriceData>();
  const [isViewPricesModalOpen, setIsViewPricesModalOpen] = useState<boolean>(false);
  const [isEditPricesModalOpen, setIsEditPricesModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(true);

  const DEFAULT_VALUES: ProductFormData = {
    nameUnique: '',
    productAppleId: '',
    productGoogleId: '',
    duration: 7,
    userLevel: {
      label: '',
      value: undefined
    },
    displayOrder: 1,
    contentColor: '#000',
    status: false,
    showOnDevice: EProductShowOnDevice.Both,
    translations: {
      [currentLang]: {
        productData: {
          name: '',
          description: [{ value: '' }],
        },
      },
    },
  };

  // Form
  const method = useForm<ProductFormData>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(productFormSchema),
  });

  const {
    fields, append, move, remove
  } = useFieldArray({
    control: method.control,
    name: `translations.${currentLang}.productData.description`,
  });

  const queryProductById = ['getProductDataById', { idParam }];

  /* Queries */
  const { data: productData, isLoading: isProductLoading } = useQuery(
    queryProductById,
    () => {
      if (idParam) return getProductByIdService(idParam);
      return undefined;
    },
    {
      enabled: roleIndex && !!idParam
    }
  );

  const queryProductPriceById = ['getProductPriceDataById', { idParam }];

  const { data: priceData, isLoading: isPriceLoading } = useQuery(
    queryProductPriceById,
    () => {
      if (idParam) return getProductPricesService(idParam);
      return undefined;
    }
  );

  const queryProductPriceDetailById = ['getProductPriceDetailDataById', { idParam }, currPriceData?.id];

  const { data: priceDetailData, isLoading: isPriceDetailLoading } = useQuery(
    queryProductPriceDetailById,
    () => {
      if (idParam && currPriceData?.id) {
        return getProductPriceDetailsByIdService(idParam, currPriceData.id);
      }
      return undefined;
    }
  );

  const { mutate: updateMutate, isLoading: isUpdateLoading } = useMutation(
    ['updateProduct'],
    async (params: UpdateProductParams) => updateProductService(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryProductById);
        queryClient.invalidateQueries(queryProductPriceById);
        queryClient.invalidateQueries(queryProductPriceDetailById);
        message.success(t('message.updateSuccess'));
      },
      onError: (err: unknown) => {
        message.error(t('message.updateError'));
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('order.promotion')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(
            e.field as keyof ProductFormData,
            { message: e.message }
          ));
        }
      },
    }
  );

  const { mutate: createMutate, isLoading: isCreateLoading } = useMutation(
    ['createProduct'],
    async (params: CreateProductParams) => createProductService(params),
    {
      onSuccess: () => {
        navigate(ROUTE_PATHS.PRODUCTS_MANAGEMENT);
      },
      onError: (err: unknown) => {
        message.error(t('message.createError'));
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(
            e.field as keyof ProductFormData,
            { message: e.message }
          ));
        }
      },
    }
  );

  /* Effects */
  // Load Values
  useEffect(() => {
    if (productData) {
      const transformedTranslations = Object.entries(productData.translations).reduce(
        (acc, [lang, data]) => ({
          ...acc,
          [lang]: {
            productData: {
              name: data.name,
              description: data.description?.map((desc) => ({
                value: desc
              })) || [{ value: '' }]
            }
          },
        }),
        {}
      );

      method.reset({
        nameUnique: productData.productData.nameUnique,
        productAppleId: productData.productData.productAppleId || '',
        productGoogleId: productData.productData.productGoogleId || '',
        duration: productData.productData.duration,
        userLevel: {
          value: productData.productData.userLevel?.id,
          label: productData.productData.userLevel?.name
        },
        displayOrder: productData.productData.displayOrder,
        contentColor: productData.productData.contentColor,
        status: productData.productData.status,
        translations: transformedTranslations,
        showOnDevice: productData.productData.showOnDevice,
      });
    } else {
      method.reset(DEFAULT_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, productData, currentLang]);

  /* Functions */
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleChangeLang = (lang: LanguageCodeTypes) => {
    setCurrentLang(lang);

    if (idParam) {
      setSearchParams(
        {
          id: String(idParam),
          locale: lang,
        },
        { replace: true }
      );
    } else {
      setSearchParams(
        {
          locale: lang,
        },
        { replace: true }
      );
    }
  };

  const onSubmit = async () => {
    const formData = method.getValues();

    const { translations } = formData;
    const formattedTranslations = Object.entries(translations).reduce(
      (acc, [lang, data]) => ({
        ...acc,
        [lang]: {
          productData: {
            name: data.productData.name,
            description: data.productData.description?.map((desc) => desc.value),
          }
        },
      }),
      {}
    );

    if (idParam) {
      updateMutate({
        ...formData,
        userLevelId: Number(formData.userLevel?.value),
        id: idParam,
        translations: formattedTranslations
      });
      // updateStatusMutate({ status: formData.status });
    } else {
      createMutate({
        ...formData,
        userLevelId: Number(formData.userLevel?.value),
        translations: formattedTranslations
      });
    }
  };

  /* Datas */
  const tableData: ProductPriceData[] = useMemo(
    () => priceData?.data.map((item) => ({
      id: item.productPriceData.id,
      name: item.productPriceData.name,
      startDate: item.productPriceData.startDate,
      endDate: item.productPriceData.endDate,
      price: item.productPriceData.price,
      priceVND: item.productPriceData.priceVND,
      createdAt: item.productPriceData.createdAt,
      updatedAt: item.productPriceData.updatedAt,
      appPrice: item.productPriceData.appPrice,
      appPriceVND: item.productPriceData.appPriceVND,
    })) || [],
    [priceData]
  );

  const columns: ColumnsType<ProductPriceData> = useMemo(
    () => [
      {
        // -- Name
        title: t('price.name'),
        key: 'name',
        render: (_name: string, data: ProductPriceData) => (
          <Typography.Text>{data.name}</Typography.Text>
        ),
      },
      {
        // -- Price
        title: `${t('price.price')}${t('system.dollar')}`,
        key: 'price',
        render: (_price: number, data: ProductPriceData) => (
          <Typography.Text>{data.price}</Typography.Text>
        ),
      },
      {
        // -- Start Date
        title: t('price.startDate'),
        key: 'startDate',
        render: (_startDate: string, data: ProductPriceData) => (
          <Typography.Text>{formatDateTime(data.startDate)}</Typography.Text>
        ),
      },
      {
        // -- End Date
        title: t('price.endDate'),
        key: 'endDate',
        render: (_endDate: string, data: ProductPriceData) => (
          <Typography.Text>{formatDateTime(data.endDate)}</Typography.Text>
        ),
      },
      {
        title: t('system.createdAt'),
        key: 'createdAt',
        width: 150,
        render: (_name: string, data: ProductPriceData) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
      // -- Updated At
      {
        title: t('system.updatedAt'),
        width: 150,
        key: 'updatedAt',
        render: (_name: string, data: ProductPriceData) => (
          <Typography.Text>{formatDateTime(data.updatedAt)}</Typography.Text>
        ),
      },
      // -- Price list
      {
        title: t('price.viewList'),
        key: 'priceList',
        width: 150,
        render: (_name: string, data: ProductPriceData) => (
          <Button
            type="link"
            onClick={() => {
              setCurrPriceData(data);
              setIsViewPricesModalOpen(true);
            }}
          >
            {t('price.viewList')}
          </Button>
        ),
      },
      // -- Action
      {
        title: t('system.action'),
        key: 'action',
        width: 150,
        render: (_name: string, data: ProductPriceData) => (
          <Button
            type="link"
            onClick={() => {
              setCurrPriceData(data);
              setIsEdit(true);
              setIsEditPricesModalOpen(true);
            }}
          >
            {t('price.editPrice')}
          </Button>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      <FormProvider<ProductFormData> {...method}>
        <HeaderPage
          fixed
          title={idParam ? t('product.editProduct') : t('product.createProduct')}
          rightHeader={(
            <Button
              type="primary"
              loading={
                isProductLoading
                || isCreateLoading
                || isUpdateLoading
                || isPriceLoading
              }
              onClick={method.handleSubmit(onSubmit)}
              disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          )}
        />
        <Spin spinning={isProductLoading}>
          <div className="t-mainlayout_wrapper">
            <Row gutter={16}>
              <Col xxl={18} xl={16} lg={16}>
                <Card>
                  <Row gutter={16}>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        Unique Name
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="nameUnique"
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name="nameUnique"
                            placeholder={`${t('system.input')} ${t('product.uniqueName')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        Display Name
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name={`translations.${currentLang}.productData.name`}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name={`translations.${currentLang}.productData.name`}
                            placeholder={`${t('system.input')} ${t('product.name')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('product.productAppleId')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="productAppleId"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name="productAppleId"
                            placeholder={`${t('system.input')} ${t('product.productAppleId')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('product.productGoogleId')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="productGoogleId"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name="productGoogleId"
                            placeholder={`${t('system.input')} ${t('product.productGoogleId')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('product.duration')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="duration"
                        defaultValue={7}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DropdownElement
                              options={productDurations}
                              placeholder={`${t('system.select')} ${t('product.duration')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('sidebar.userLevels')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="userLevel"
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DropdownElement
                              type="userLevel"
                              placeholder="---"
                              locale={currentLang}
                              value={value}
                              size="large"
                              isGetOption
                              onChange={onChange}
                            />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('filterField.displayOrder')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="displayOrder"
                        defaultValue={0}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            type="number"
                            className="u-mt-8"
                            name="displayOrder"
                            placeholder={`${t('system.input')} ${t('filterField.displayOrder')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>{t('product.contentColor')}</Typography.Text>
                      <Controller
                        name="contentColor"
                        render={({ field: { value, onChange }, fieldState }) => (
                          <div className="u-mt-8">
                            <ChromePicker
                              disableAlpha
                              color={value || '#000'}
                              onChange={(color: ColorResult) => onChange(color.hex)}
                            />
                            {fieldState.error && (
                              <span className="a-input_errorMessage">
                                {fieldState.error.message}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('product.showOnDevice')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="showOnDevice"
                        defaultValue={7}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DropdownElement
                              options={showOnDeviceOptions}
                              placeholder={`${t('system.select')} ${t('product.showOnDevice')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-16">
                      <Typography.Text strong>
                        {t('product.status')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="status"
                        defaultValue={false}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <div className="u-mt-8">
                            <Switch checked={value} onChange={onChange} />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </div>
                        )}
                      />
                    </Col>

                  </Row>
                  <Row>
                    <Col span={24} className="u-mb-16">
                      <Typography.Text strong>
                        {t('product.description')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <DragDropContext
                        onDragEnd={(result) => {
                          if (!result.destination) return;
                          const destIdx = result.destination.index;
                          move(result.source.index, destIdx);
                        }}
                      >
                        <Droppable droppableId="banner">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {fields.map((ele, index) => (
                                <Draggable key={ele.id} draggableId={ele.id} index={index}>
                                  {(providedDrag) => (
                                    <div
                                      ref={providedDrag.innerRef}
                                      {...providedDrag.draggableProps}
                                      {...providedDrag.dragHandleProps}
                                      style={{
                                        ...providedDrag.draggableProps.style,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                      }}
                                    >
                                      <div style={{ display: 'flex', width: '100%' }}>
                                        <div className="p-banner_dragHeader_holder">
                                          <HolderOutlined />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <Controller
                                            name={`translations.${currentLang}.productData.description[${index}].value`}
                                            render={
                                              ({ field: { value, onChange }, fieldState }) => (
                                                <div className="u-mt-8" style={{ flex: 1 }}>
                                                  <TextArea value={value || ''} onChange={onChange} />
                                                  {fieldState.error && (
                                                    <span className="a-input_errorMessage">
                                                      {fieldState.error.message}
                                                    </span>
                                                  )}
                                                </div>
                                              )
                                            }
                                          />
                                        </div>
                                        <Button
                                          type="text"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                          icon={<DeleteOutlined />}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Button
                        type="primary"
                        className="btn-center u-mt-24"
                        onClick={() => append({
                          value: '',
                        })}
                      >
                        <PlusOutlined />
                        {t('system.addNew')}
                      </Button>
                    </Col>
                  </Row>
                  {idParam && (

                    <Row className="u-mb-16">
                      <Col span={24}>
                        <PageTable
                          isLoading={isPriceLoading || isPriceDetailLoading}
                          noCheckbox
                          leftCustomForm={(
                            <Typography.Text strong>
                              {t('price.currentPriceList')}
                              {' '}
                            </Typography.Text>
                          )}
                          tableProps={{
                            initShowColumns: [
                              'name',
                              'price',
                              'startDate',
                              'endDate',
                              'createdAt',
                              'updatedAt',
                              'priceList',
                              'action',
                            ],
                            columns,
                            pageData: tableData,
                            currentPage,
                            pageSize: currentView,
                            handleSetCurrentPage,
                            handleSetCurrentView,
                            total: priceData?.meta.total || 1,
                            noBaseCol: true,
                            noDeleteLanguage: true,
                          }}
                        />
                      </Col>
                      <Button
                        type="primary"
                        onClick={() => {
                          setCurrPriceData(undefined);
                          setIsEdit(false);
                          setIsEditPricesModalOpen(true);
                        }}
                      >
                        {t('price.customize')}
                      </Button>
                    </Row>
                  )}
                </Card>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <ManagementInfo
                  createdDate={formatDateTime(productData?.productData.createdAt) || ''}
                  createdBy="Admin"
                  lastUpdated={formatDateTime(productData?.productData.updatedAt) || ''}
                  lastUpdatedBy={productData?.updaterData?.name || ''}
                  languageList={languageOptions}
                  currentLang={currentLang}
                  handleChangeLang={(value) => {
                    if (value) {
                      handleChangeLang(value as LanguageCodeTypes);
                    }
                  }}
                />
              </Col>
            </Row>
          </div>
        </Spin>
      </FormProvider>
      <PriceListModal
        data={priceDetailData}
        open={isViewPricesModalOpen}
        onClose={() => setIsViewPricesModalOpen(false)}
      />
      <EditPricesModal
        isEdit={isEdit}
        productId={idParam}
        data={priceDetailData}
        open={isEditPricesModalOpen}
        onClose={() => setIsEditPricesModalOpen(false)}
        queryClient={queryClient}
      />
    </>
  );
};
export default ProductsDetail;
