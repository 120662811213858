import { InboxOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Modal, ModalProps,
  Row,
  Space,
  Spin,
  Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getUsageHistoryByIdService } from 'common/services/usageHistories';
import { formatDateTime } from 'common/utils/functions';

const UsageHistoryDetailModal: React.FC<ModalProps & { id: number }> = ({
  id, onOk, onCancel
}) => {
  const { t } = useTranslation();

  const { data: resData, isLoading } = useQuery(
    ['get-usage-history-by-id', id],
    () => getUsageHistoryByIdService(id),
    {
      enabled: id > -1
    }
  );

  return (
    <Modal
      title={(<Typography.Title level={3} style={{ textAlign: 'center' }}>{t('usageHistories.usageHistoryDetail')}</Typography.Title>)}
      width="60vw"
      open={id > -1}
      centered
      onOk={onOk}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Spin spinning={isLoading}>
        {resData ? (
          <>
            <Row gutter={16} className="u-mt-16">
              <Col span={24}>
                <Typography.Text strong>
                  {t('usageHistories.deviceId')}
                  :
                  {' '}
                </Typography.Text>
                <Typography.Text>
                  {resData?.deviceId || ''}
                </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong>
                  {t('usageHistories.startAt')}
                  :
                  {' '}
                </Typography.Text>
                <Typography.Text>
                  {formatDateTime(resData?.startAt || '')}
                </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong>
                  {t('usageHistories.endAt')}
                  :
                  {' '}
                </Typography.Text>
                <Typography.Text>
                  {formatDateTime(resData?.endAt)}
                </Typography.Text>
              </Col>
            </Row>
            {resData?.functionals.length > 0 && resData?.functionals.map((item, idx) => (
              <Row className="u-mt-8" style={{ width: '100%' }} key={`${item.functionalType}-${idx.toString()}`}>
                <Card style={{ width: '100%' }}>
                  <Col span={24}>
                    <Typography.Text strong>
                      {t('usageHistories.functionalType')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {item.functionalType || ''}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text strong>
                      {t('usageHistories.appOrMonthLimit')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {item.appOrMonthLimit || 0}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text strong>
                      {t('usageHistories.totalUsed')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {item.totalUsed || 0}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text strong>
                      {t('usageHistories.webOrWeekLimit')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {item.webOrWeekLimit || 0}
                    </Typography.Text>
                  </Col>
                  {item.usedInWeek.length > 0 && (
                  <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="e-usageHistory-card u-mt-8" title={<Typography.Text strong>{t('usageHistories.usedInWeek')}</Typography.Text>}>
                      {item.usedInWeek.map((ele, i) => (
                        <div key={`${ele.startAt}-${i.toString()}`} className="e-usageHistory-card-item">
                          <Col span={24}>
                            <Typography.Text strong>
                              {t('usageHistories.quantityUsed')}
                              :
                              {' '}
                            </Typography.Text>
                            <Typography.Text>
                              {ele.quantityUsed || 0}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {t('usageHistories.startAt')}
                              :
                              {' '}
                            </Typography.Text>
                            <Typography.Text>
                              {formatDateTime(ele.startAt || '')}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {t('usageHistories.endAt')}
                              :
                              {' '}
                            </Typography.Text>
                            <Typography.Text>
                              {formatDateTime(ele.endAt || '')}
                            </Typography.Text>
                          </Col>
                        </div>
                    ))}
                    </Card>
                  </Col>
)}
                  {item.usedInMonth.length > 0 && (
                  <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="e-usageHistory-card u-mt-8" title={<Typography.Text strong>{t('usageHistories.usedInMonth')}</Typography.Text>}>
                      {item.usedInMonth.map((ele, i) => (
                        <div key={`${ele.startAt}-${i.toString()}`} className="e-usageHistory-card-item">
                          <Col span={24}>
                            <Typography.Text strong>
                              {t('usageHistories.quantityUsed')}
                              :
                              {' '}
                            </Typography.Text>
                            <Typography.Text>
                              {ele.quantityUsed || 0}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {t('usageHistories.startAt')}
                              :
                              {' '}
                            </Typography.Text>
                            <Typography.Text>
                              {formatDateTime(ele.startAt || '')}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text strong>
                              {t('usageHistories.endAt')}
                              :
                              {' '}
                            </Typography.Text>
                            <Typography.Text>
                              {formatDateTime(ele.endAt || '')}
                            </Typography.Text>
                          </Col>
                        </div>
                    ))}
                    </Card>
                  </Col>
)}
                </Card>
              </Row>
            ))}
          </>
      ) : (
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          <InboxOutlined style={{ fontSize: '32px', color: '#4a4a4a' }} />
          <Typography.Text style={{ textAlign: 'center', color: '#4a4a4a' }}>{t('usageHistories.noData')}</Typography.Text>
        </Space>
      )}
      </Spin>
    </Modal>
  );
};

export default UsageHistoryDetailModal;
