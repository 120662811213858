import { SaveOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, DatePicker, message, Modal, Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller, FormProvider,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import PageTable from 'common/components/PageTable';
import {
  createRedeemService, getRedeemService, revokeRedeemCodeService, updateRedeemService
} from 'common/services/extends/redeem';
import { CreateRedeemParams, RevokeRedeemParam, UpdateRedeemParams } from 'common/services/extends/redeem/types';
import { ROUTE_PATHS, typeRedeemOptions } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import { redeemFormSchema } from 'common/utils/schemas';

type RedeemFormTypes = {
  name: string
  code: string
  type: 1 | 2, // 1: SingleUse, 2: MultiUse
  startDate: string,
  endDate: string,
  quantity: number,
  studentIds: OptionType[],
  product?: OptionType,
};

export type ColumnsTypes = {
  studentId: number
  studentName: string
  studentEmail: string
  code: string
  status: number
  redeemedDate: string
};

const DEFAULT_VALUES: RedeemFormTypes = {
  name: '',
  code: '',
  product: {
    label: '',
    value: undefined
  },
  quantity: 1,
  type: 1,
  endDate: '',
  studentIds: [],
  startDate: ''
};

const RedeemDetail: React.FC<ActiveRoles> = ({
  roleUpdate,
  roleIndex,
  roleCreate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { defaultWebsiteLanguage, defaultPageSize } = useAppSelector((state) => state.system);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);

  const queryClient = useQueryClient();

  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  const idParams = searchParams.get('id');
  const [currentLang] = useState<string>(localeParams);

  const method = useForm<RedeemFormTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(redeemFormSchema),
    mode: 'onChange'
  });

  const queryUserLevelById = ['getRedeemDataId', { idParams }];

  const { data: redeemData, isLoading: redeemDataLoading } = useQuery(
    queryUserLevelById,
    () => {
      if (idParams) {
        return getRedeemService(idParams);
      }
      return undefined;
    },
    {
      enabled: roleIndex && !!idParams
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['redeem-create'],
    async (params: CreateRedeemParams) => createRedeemService(params),
    {
      onSuccess: () => {
        navigate(ROUTE_PATHS.REDEEM_MANAGEMENT);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            method.setError(error.field as keyof RedeemFormTypes, { message: error.message || '' });
          });
        } else {
          message.error(t('message.updateError'));
        }
      },
    }
  );

  const { mutate: revokeMutate, isLoading: revokeLoading } = useMutation(
    ['revoke-mutate'],
    async (params: RevokeRedeemParam) => revokeRedeemCodeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryUserLevelById);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['redeem-update'],
    async (params: UpdateRedeemParams) => updateRedeemService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryUserLevelById);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          if (errors[0].code === 'active') {
            message.error(errors[0].message);
          } else {
            errors.forEach((error: ErrorResponse) => {
              method.setError(error.field as keyof RedeemFormTypes, { message: error.message || '' });
            });
          }
        } else {
          message.error(t('message.updateError'));
        }
      },
    }
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }

    const formData = method.getValues();
    const dataRequest: CreateRedeemParams = {
      ...(formData.code && { code: formData.code }),
      productId: formData.product?.value ? Number(formData.product?.value) : 1,
      name: formData.name,
      quantity: formData.quantity,
      startDate: formData.startDate,
      endDate: formData.endDate,
      type: formData.type,
      studentIds: formData.studentIds.map((item) => item.value as number) || []
    };
    if (idParams) {
      updateMutate({
        id: idParams,
        ...dataRequest
      });
    } else {
      createMutate(dataRequest);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleStatus = (status: number) => {
    switch (status) {
      case 0:
        return t('redeem.notStartedYet');
      case 1:
        return t('redeem.active');
      case 2:
        return t('redeem.expired');
      case 3:
        return t('redeem.redeemed');
      default:
        return t('redeem.revoked');
    }
  };

  const studentIdsData: OptionType[] = useMemo(() => (
    redeemData?.redeemData.studentIds.map((val) => ({
      label: val?.name || '',
      value: val.id
  })) || []), [redeemData?.redeemData]);

  useEffect(() => {
    if (redeemData) {
      method.reset({
        type: redeemData.redeemData.type,
        product: {
          label: redeemData.redeemData?.product?.name || '',
          value: redeemData.redeemData?.product?.id || ''
        } as OptionType,
        code: redeemData.redeemData?.code || '',
        endDate: redeemData.redeemData?.endDate,
        name: redeemData.redeemData?.name,
        quantity: redeemData.redeemData?.quantity,
        startDate: redeemData.redeemData?.startDate,
        studentIds: studentIdsData
      });
    } else {
      method.reset(DEFAULT_VALUES);
    }
  }, [method, redeemData, studentIdsData]);

  const tableData: ColumnsTypes[] = useMemo(() => (
    redeemData?.redeemData.redeemCodes.map((val) => ({
      studentId: val?.id,
      code: val?.code || '',
      studentName: val?.student?.name || '',
      studentEmail: val?.student?.email || '',
      redeemedDate: val?.redeemedDate || '',
      status: val?.status,
  })) || []), [redeemData]);

  const columns: ColumnsType<ColumnsTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentId}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('redeem.campaign'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'email',
      key: 'email',
      width: 300,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentEmail}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      width: 200,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {handleStatus(data.status)}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.redeemedDate'),
      dataIndex: 'redeemedDate',
      width: 250,
      key: 'redeemedDate',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemedDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: ColumnsTypes) => (
        <Space>
          <Tooltip title="Revoke">
            <Button
              disabled={!roleUpdate || revokeLoading || !idParams}
              loading={revokeLoading}
              icon={<ThunderboltOutlined />}
              onClick={() => {
                if (!idParams) return;
                Modal.confirm({
                  className: 't-pagetable_deleteRecordModal',
                  autoFocusButton: 'cancel',
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'primary',
                  },
                  okButtonProps: {
                    type: 'default',
                  },
                  title: t('Đồng ý thu hồi mã này ?'),
                  onOk: () => {
                    revokeMutate({
                      id: idParams,
                      redeemCodeIds: [_data.studentId]
                    });
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]), [handleStatus, idParams, revokeLoading, revokeMutate, roleUpdate, t]);

  return (
    <FormProvider<RedeemFormTypes> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('redeem.editRedeem') : t('redeem.createRedeem')}
        rightHeader={(
          <Button
            type="primary"
            loading={redeemDataLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={!roleUpdate}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={redeemDataLoading || createLoading || updateLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <div className="u-mt-16">
                  <Row gutter={16}>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.name')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="name"
                            placeholder={`${t('system.input')} ${t('redeem.name')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.type')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="type"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={typeRedeemOptions}
                              placeholder={`${t('system.select')} ${t('redeem.type')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.code')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="code"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="code"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                            disabled
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.quantityCode')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="quantity"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            type="number"
                            name="quantity"
                            placeholder={`${t('system.input')}  ${t('redeem.quantityCode')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.startDate')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="startDate"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DatePicker
                              value={value ? dayjs(value) : null}
                              onChange={onChange}
                              style={{ width: '100%' }}
                              size="large"
                              className="u-mt-8"
                              showTime
                            />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.endDate')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="endDate"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DatePicker
                              value={value ? dayjs(value) : null}
                              onChange={onChange}
                              style={{ width: '100%' }}
                              className="u-mt-8"
                              size="large"
                              showTime
                            />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('redeem.product')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="product"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <DropdownElement
                              type="product"
                              placeholder={`${t('system.select')} ${t('redeem.product')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                              isGetOption
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {(error as any).value.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('redeem.studentList')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="studentIds"
                        render={({ field: { value, onChange }, fieldState: { error }, }) => (
                          <>
                            <DropdownElement
                              type="student"
                              placeholder={`${t('system.select')} ${t('redeem.student')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              multiple={{}}
                              isGetOption
                              onChange={onChange}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={redeemData?.redeemData?.createdAt ? dayjs(redeemData.redeemData?.createdAt).fromNow() : ''}
                createdBy="Admin"
                lastUpdated={redeemData?.redeemData?.updatedAt ? dayjs(redeemData.redeemData?.updatedAt).fromNow() : ''}
                lastUpdatedBy="Admin"
              />
            </Col>
            {idParams && tableData.length > 0 && (
              <Col xxl={24}>
                <Card
                  className="u-mt-16"
                  title={(
                    <Space direction="horizontal" style={{ justifyContent: 'space-between', width: '100%' }}>
                      <Typography.Title level={4}>{t('redeem.redeemCodes')}</Typography.Title>
                      <Button
                        type="primary"
                        onClick={() => {
                          const idArrs = redeemData
                          ?.redeemData.redeemCodes.map((item) => item.id) || [];
                          Modal.confirm({
                            className: 't-pagetable_deleteRecordModal',
                            autoFocusButton: 'cancel',
                            okText: t('system.ok'),
                            cancelText: t('system.cancel'),
                            cancelButtonProps: {
                              type: 'primary',
                            },
                            okButtonProps: {
                              type: 'default',
                            },
                            title: t('Đồng ý thu hồi tất tất cả ?'),
                            onOk: () => {
                              if (idArrs.length > 0) {
                                revokeMutate({
                                  id: idParams,
                                  redeemCodeIds: idArrs
                                });
                              }
                            },
                          });
                        }}
                        disabled={!roleCreate || revokeLoading}
                        loading={revokeLoading || redeemDataLoading}
                      >
                        <ThunderboltOutlined />
                        Revoke All
                      </Button>
                    </Space>
                )}
                >
                  <Col>
                    <PageTable
                      isLoading={redeemDataLoading}
                      noCheckbox
                      tableProps={{
                      initShowColumns: ['id', 'name', 'email', 'code', 'status', 'redeemedDate', 'action'],
                      columns,
                      pageData: tableData,
                      currentPage,
                      pageSize: currentView,
                      handleSetCurrentPage,
                      handleSetCurrentView,
                      total: (currentView && tableData.length > 0)
                      ? Math.floor(tableData.length / currentView) + 1 : 1,
                      noBaseCol: true,
                      noDeleteLanguage: true,
                      }}
                    />
                  </Col>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default RedeemDetail;
