import axiosInstance from '../common/instance';

import {
  CountryItem,
  DistrictItem, IDistrict, LocationItem
} from './types';

export const getProvincesListService = async (): Promise<LocationItem[]> => {
  const res = await axiosInstance.get('locations/provinces-by-vietnamese');
  return res.data.data;
};

export const getDistrictsListService = async (): Promise<IDistrict[]> => {
  const res = await axiosInstance.get('locations/districts-by-vietnamese');
  return res.data.data;
};

export const getDistrictListService = async (id?: string): Promise<LocationItem[]> => {
  const res = await axiosInstance.get(`locations/districts/${id}`);
  return res.data.data;
};

export const getWardsListService = async (id?: string): Promise<LocationItem[]> => {
  const res = await axiosInstance.get(`locations/wards/${id}`);
  return res.data.data;
};

export const getDistrictByProvinceIdsService = async (ids?: string): Promise<DistrictItem[]> => {
  const res = await axiosInstance.get('locations/districts-by-province-ids', { params: { provinceIds: ids } });
  return res.data.data;
};

export const getCountriesListService = async (
  params: BaseFilterParams
): Promise<APIPaginationResponse<CountryItem[]>> => {
  const res = await axiosInstance.get('countries', { params });
  return res.data;
};
