import { CheckSquareOutlined } from '@ant-design/icons';
import {
  Modal, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateTime } from 'common/utils/functions';

export interface DetailModalProps {
  id: number,
  modelId: number,
  model: string,
  action: string,
  modelDataName: string,
  userIdName: string,
  userIdEmail: string,
  modelDataLevel: number,
  modelDataRequiredLogin: boolean,
  createdAt: string,
}

interface UpdateContactModalProps {
  isOpen: boolean;
  handleClose: () => void;
  detailData: DetailModalProps;
}

const DetailModal: React.FC<UpdateContactModalProps> = ({
  detailData, isOpen, handleClose
}) => {
  /* Hooks */
  const { t } = useTranslation();

  return (
    <Modal
      title={<Typography.Title level={3}>{t('featureHistories.featureHistoriesDetail')}</Typography.Title>}
      open={isOpen}
      centered
      onCancel={handleClose}
      width={800}
      footer={null}
    >
      <div className="t-featureHistories-detail_form">
        <div className="t-featureHistories-detail_info">
          <Typography.Text strong>
            ID:
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.id}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info">
          <Typography.Text strong>
            {t('featureHistories.modelId')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.modelId}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.model')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.model}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.action')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.action}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.modelDataName')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.modelDataName}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.modelDataLevel')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.modelDataLevel}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.modelDataRequiredLogin')}
            :
            {' '}
          </Typography.Text>
          {detailData.modelDataRequiredLogin
          ? <CheckSquareOutlined style={{ backgroundColor: '#00ff00', color: 'white' }} />
           : null}
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.userIdName')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.userIdName}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('featureHistories.userIdEmail')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {detailData.userIdEmail}
          </Typography.Text>
        </div>
        <div className="t-featureHistories-detail_info u-mt-8">
          <Typography.Text strong>
            {t('system.createdAt')}
            :
            {' '}
          </Typography.Text>
          <Typography.Text>
            {formatDateTime(detailData.createdAt)}
          </Typography.Text>
        </div>
      </div>
    </Modal>
  );
};

export default DetailModal;
